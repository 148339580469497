import JsonfySalesOrderList from './tratarJson'
import JsonfyFinancialStatementList from './tratarJsonFicha'
import JsonfyTransacao from './tratarJsonTransacao'

function FormUtilsDownload (data){
  let dataTratada
  if (data[0].hasOwnProperty('GrossAmount')) {
    dataTratada = JsonfySalesOrderList(data)
  } else if (data[0].hasOwnProperty('RefCustSettlement')) {
    dataTratada = JsonfyFinancialStatementList(data)
  } else {
    dataTratada = JsonfyTransacao(data)
  }
  dataTratada = JSON.parse(JSON.stringify(dataTratada), (key, value) => {
    if (value === 'null') {
      return null
    }
    return value
  })
  const jsonStr = JSON.stringify(dataTratada, null, 2)
  const blob = new Blob([jsonStr.replace(/(\r\n|\n|\r|\t)/gm,"")], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${data[0].MatriculaId}.json`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export default FormUtilsDownload
