// Components
import React from 'react'
import Ov from './pages/Ov'
import Ficha from './pages/Ficha'
import Transacoes from './pages/Transacoes'

// External Components
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { MsalProvider } from "@azure/msal-react";

// Authenticator
import {AuthProvider} from "./context/AuthContext";
// Pages
import Navbar from './components/navbar/Navbar'
import { ToastContainer } from 'react-toastify'

// css
import './App.css'

function App({ msalInstance }) {
  return (
    <div className='app'>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <AuthProvider>
            <Navbar />
            <Routes>
              <Route path='/' element={<Ov />}></Route>
              <Route path='/ficha' element={<Ficha />}></Route>
              <Route path='/transacoes' element={<Transacoes />}></Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </MsalProvider>
      <ToastContainer />
    </div>
  )
}

export default App
