function JsonfyTransacao(dataReceived) {
    const transactions = {
        service: {
            firstInstallment: [],
            monthlyPayment: []
        },
        material: {
            firstInstallment: [],
            monthlyPayment: []
        },
        upSelling: [],
        feesEvents: []
    };

    dataReceived.forEach((item) => {
        const vars = {
            bankAccount: item.bankAccount || null,
            bankingBillet: item.bankingBillet || null,
            custCard: item.custCard || null,
            transAmountCur: parseFloat(item.transAmountCur) || 0.0,
            dueDate: item.dueDate || '0001-01-01T12:00:00',
            invoiceId: item.invoiceId || '',
            cashDiscAmount: parseFloat(item.cashDiscAmount) || 0.0,
            cashDiscDate: item.cashDiscDate || '0001-01-01T12:00:00',
            configId: item.configId || '',
            installmentNum: parseFloat(item.installmentNum) || 0,
            invoiceDate: item.invoiceDate || '0001-01-01T12:00:00',
            itemId: item.itemId || '',
            offsetAccountNum: item.offsetAccountNum || '',
            offSetAccountType: parseFloat(item.offSetAccountType) || 0,
            paymMode: item.paymMode || '',
            paymSpec: item.paymSpec || '',
            paymTermId: item.paymTermId || '',
            qtyOfInstallments: parseFloat(item.qtyOfInstallments) || 0,
            transTxt: item.transTxt || ''
        };

        if (item.tipoServico === 'material') {
            if (item.configId === '1ª PARCELA') {
                transactions.material.firstInstallment.push(vars);
            } else {
                transactions.material.monthlyPayment.push(vars);
            }
        } else if (item.tipoServico === 'service') {
            if (item.configId === '1ª PARCELA') {
                transactions.service.firstInstallment.push(vars);
            } else {
                transactions.service.monthlyPayment.push(vars);
            }
        } else if (item.tipoServico === 'upSelling') {
                transactions.upSelling.push(vars);
        } else if (item.tipoServico === 'feesEvents') {
                transactions.feesEvents.push(vars);
        }
    });
    if (transactions.service.firstInstallment.length === 0) {
        transactions.service.firstInstallment = null;
    }
    if (transactions.service.monthlyPayment.length === 0) {
        transactions.service.monthlyPayment = null;
    }
    if (transactions.material.firstInstallment.length === 0) {
        transactions.material.firstInstallment = null;
    }
    if (transactions.material.monthlyPayment.length === 0) {
        transactions.material.monthlyPayment = null;
    }
    if (transactions.upSelling.length === 0) {
        transactions.upSelling = null;
    }
    if (transactions.feesEvents.length === 0) {
        transactions.feesEvents = null;
    }

    return {
        _contract: {
            DataAreaId: dataReceived[0].DataAreaId || '',
            FiscalEstablishmentId: dataReceived[0].FiscalEstablishmentId || '',
            MatriculaId: dataReceived[0].MatriculaId || '',
            financialTransDetail: {
                financialMovType: parseFloat(dataReceived[0].financialMovType) || 0,
                custAccount: dataReceived[0].custAccount || '',
                transStatus: parseFloat(dataReceived[0].transStatus) || 0,
                transactions
            }
        }
    };
}

export default JsonfyTransacao;