import { useState } from "react";
export function useForm(steps) {
  const [currentStep, setCurrentStep] = useState(0);

  function changeStep(i, e) {
    if (e) e.preventDefault();

    if (i < 0 || i >= steps.length) return;

    setCurrentStep(i);
  }

  return {
    currentStep,// step atual que seta com o valor passado "i"
    isLastStep: currentStep + 1 === steps.length ? true : false, // pega o step atual e verifica se é o ultimo step
    isFirstStep: currentStep === 0 ? true : false, // pega o step atual e verifica se é o primeiro step
    currentComponent: steps[currentStep],// pega o step atual e retorna o componente
    changeStep, // chama a funcao para mudar o step
  };
}
