const Jsonfy = (dataReceived) => {
  const salesOrderList = dataReceived.map((item) => {
    return {
      "CustAccount": item.CustAccount || '',
      "ItemId": item.ItemId || '',
      "Month": parseFloat(item.Month) || 0,
      "Year": parseFloat(item.Year) || 0,
      "AmountCur": parseFloat(item.AmountCur) || 0,
      "DiscAmountCur": parseFloat(item.DiscAmountCur) || 0,
      "configId": item.configId || '',
      "InventLocationId": item.InventLocationId || '',
      "FiscalEstablishment": item.FiscalEstablishment || 0,
      "PaymSched": item.PaymSched || '',
      "Scholarship": parseFloat(item.Scholarship) || 0,
      "GrossAmount": parseFloat(item.GrossAmount) || 0,
      "MethodDiscount": parseFloat(item.MethodDiscount) || 0,
      "BillingItemId": item.BillingItemId || '',
      "MatriculaID": item.MatriculaID || '',
      "TypeOfMovement": parseFloat(item.TypeOfMovement) || 0,
      "StartSchoolYear": item.StartSchoolYear || '0001-01-01T12:00:00',
      "CashDiscAmount": parseFloat(item.CashDiscAmount) || 0,
      "MethodDiscountPercentage": parseFloat(item.MethodDiscountPercentage) || 0,
      "NetValue": parseFloat(item.NetValue) || 0,
      "CashDiscAmountCur": parseFloat(item.CashDiscAmountCur) || 0,
      "DeliveryDate": item.DeliveryDate || '0001-01-01T12:00:00',
      "InstallmentNum": parseFloat(item.InstallmentNum) || 0,
      "EnrollmentSalesOrderRecId": parseFloat(item.EnrollmentSalesOrderRecId) || 0,
      "SegmentCode": item.SegmentCode || '',
      "ScholarshipPercentage": parseFloat(item.ScholarshipPercentage) || 0,
    }
  });
  return {
    "_contract": {
      "DataAreaId": dataReceived[0].DataAreaId, 
      "MatriculaId": dataReceived[0].MatriculaId, 
      "SalesOrderList": salesOrderList
    }
  };
};

export default Jsonfy;
