export function deleteServ(data, setData, index, notify) {
  let cont1 = 0
  let cont2 = 0
  let ItemId = data[index].ItemId
  data.map((item, i) => {
    if (item.ItemId === ItemId) {
      //verifica o ultimo data do servico
      cont1++
    } else {
      cont2++
    }
  })

  let newData = [...data]
  let j = index
  let jsonDataAreaId
  let jsonDataMatriculaId
  jsonDataAreaId = newData[0].DataAreaId
  jsonDataMatriculaId = newData[0].MatriculaId
  for (let i = index; i < index + cont1;) {
    newData.splice(index, 1)
    i++
  }
  //acabou a remoção
  setData(prev => {
    const updateData = [...newData]
    updateData[0] = {
      ...updateData[0],
      DataAreaId: jsonDataAreaId,
      MatriculaId: jsonDataMatriculaId
    }
    return updateData
  })

  notify('Serviço removido com sucesso!')
}

export function updateQuantServ(
  data,
  setData,
  value,
  notify,
  quantidadeServ,
  groupedData,
  formTemplate,
  setQuantidadeServ,
  setGroupedData,
) {
  let dataVazio = 0
  let parcCont = 0
  //pegar o groupedData
  if (data[0].hasOwnProperty('itemId')) {
    data.forEach(formTemplate => {
      const itemId = formTemplate.itemId
      if (itemId === '') {
        dataVazio++
      }
    })
  } else {
    data.forEach(formTemplate => {
      const ItemId = formTemplate.ItemId
      if (ItemId === '') {
        dataVazio++
      }
    })
  }
  if (value < quantidadeServ) {
    let newData = [...data]
    if (data[0].hasOwnProperty('itemId')) {
      let itemId = data[data.length - 1].itemId
      data.forEach(formTemplate => {
        if (itemId === formTemplate.itemId) {
          parcCont++
        }
      })
    } else {
      let ItemId = data[data.length - 1].ItemId
      data.forEach(formTemplate => {
        if (ItemId === formTemplate.ItemId) {
          parcCont++
        }
      })
    }
    for (let i = 0; i < parcCont; i++) {
      newData.splice(data.length - parcCont, 1)
    }
    setData(newData)
    setQuantidadeServ(groupedData.length)
  } else {
    if (dataVazio > 0) {
      notify('Ja possui um data não preenchido!')
    } else {
      setData(prev => {
        const newData = [...prev]
        newData.push(formTemplate)
        return newData
      })
      setQuantidadeServ(groupedData.length)
    }
  }
}
