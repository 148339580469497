function filtrarTransacao(dataReceived) {
    const vars = dataReceived.map((item) => {
        return {
            "bankAccount": item.bankAccount || null,
            "bankingBillet": item.bankingBillet || null,
            "custCard": item.custCard || null,
            "transAmountCur": parseFloat(item.transAmountCur) || 0.0,
            "dueDate": item.dueDate || '0001-01-01T12:00:00',
            "invoiceId": item.invoiceId || '',
            "cashDiscAmount": parseFloat(item.cashDiscAmount) || 0.0,
            "cashDiscDate": item.cashDiscDate || '0001-01-01T12:00:00',
            "configId": item.configId || '',
            "installmentNum": parseFloat(item.installmentNum) || 0,
            "invoiceDate": item.invoiceDate || '0001-01-01T12:00:00',
            "itemId": item.itemId || '',
            "offsetAccountNum": item.offsetAccountNum || '',
            "offSetAccountType": parseFloat(item.offSetAccountType) || 0,
            "paymMode": item.paymMode || '',
            "paymSpec": item.paymSpec || '',
            "paymTermId": item.paymTermId || '',
            "qtyOfInstallments": parseFloat(item.qtyOfInstallments) || 0,
            "transTxt": item.transTxt || ''
        }
    });
    return vars;
}
export default filtrarTransacao;