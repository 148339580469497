import './Navbar.css'
import React, { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import profileImage from '../../assets/images/profile.png';
import { useMsal } from '@azure/msal-react';

const Navbar = () => {
  const [userName, setUserName] = useState(null);
  const { userImage } = useContext(AuthContext);
  const [msg, setMsg] = useState(null);
  const location = useLocation();
  const msal = useMsal();

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 4 && currentHour < 12) {
      setMsg('Bom Dia!');
    } else if (currentHour >= 12 && currentHour < 18) {
      setMsg('Boa Tarde!');
    } else {
      setMsg('Boa Noite!');
    }

    if (sessionStorage.getItem('@JSONAPP/nomeUsuarioLogado')) {
      let nomeUsuarioLogado = sessionStorage.getItem('@JSONAPP/nomeUsuarioLogado');
      nomeUsuarioLogado = nomeUsuarioLogado.split(' ')[0];
      setUserName(nomeUsuarioLogado);
    }
  }, [userImage]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('@JSONAPP/nomeUsuarioLogado');
    sessionStorage.removeItem('@JSONAPP/emailUsuarioLogado');
    sessionStorage.removeItem('@JSONAPP/msalToken');
    msal.instance.logoutRedirect();
  }

  const isActiveLink = (path) => {
    return location.pathname === path ? 'activeLink' : ''
  }

  return <div className='navBar'>
    <div className='LinkContainer'>
      <Link to='/' className={isActiveLink('/')}>OV
      <div className='barra'/></Link>
      <Link to='/ficha' className={isActiveLink('/ficha')}>FICHA
      <div className='barra'/></Link>
      <Link to='/transacoes' className={isActiveLink('/transacoes')}>TRANSAÇÕES
      <div className='barra'/></Link>
    </div>
    <div className='userDetails'>
      <div>
        <div className='msg' >{msg}</div>
        <Button
          className='userName'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="elevated"
          endIcon={<KeyboardArrowDownIcon />}
        >
          {userName}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          PaperProps={{
            style: {
              width: '12ch',
            },
          }}
        >
          <MenuItem className='NavMenuItem' onClick={handleLogout} >
            Sair{' '}<ExitToAppIcon/>
          </MenuItem>
        </Menu>
      </div>
      <div >
        <img src={userImage ? userImage : profileImage} className='userImage'/>
      </div>
    </div>
  </div>
}

export default Navbar
