// Components
import UserFormOV from '../components/forms/inputsIntroOV'
import TableJSON from '../components/tables/TableFicha'
import CardServico from '../components/card/CardFicha'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

//hooks
import { useForm } from '../hook/useForm'

//utils
import { handleFieldsUpdate, updateFieldsHandler, updateFieldHandler  } from '../utils/FormUtilsOv'
import FormUtilsDownload from '../utils/FormUtilsJsonDownload'
import FormUtilsUpload from '../utils/FormUtilsJsonUpload'
import { deleteServ, updateQuantServ} from '../utils/FormUtilsUpdateServ'

//external components
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'

const FinancialStatementList = {
  DataAreaId: '',
  MatriculaId: '',
  MatriculaID: '',
  FiscalEstablishmentId: '',
  InstallmentAmountCur: '',
  InstallmentNetAmount: '',
  PaymentMethod: '',
  TotalDiscount: '',
  CashdiscDueDate: '',
  CashDiscValue: '',
  DueDate: '',
  InvoiceId: '',
  ItemId: '',
  Installment: '',
  ConfigId: 'MENSALIDADE',
  Responsible: '',
  TransDate: '',
  ReserveValue: '',
  ScholarshipValue: '',
  PaymMethodDiscAmount: '',
  TransactionType: '',
  TypeOfMovement: '',
  MovementDate: '',
  PaymentStatus: '',
  AmountPaid: '',
  TypeOfPayment: '',
  AgreementDocument: 'null',
  RefCustTrans: '',
  RefCustTransOpen: '',
  RefCustSettlement: ''
}

const notify = (mensagem, tipo) => {
  if (tipo === 'success') {
    toast.success(mensagem, {
      autoClose: 1500
    })
  } else {
    toast.warn(mensagem, {
      autoClose: 1500
    })
  }
}

function Ficha () {
  const location = useLocation()
  const { pathname } = location

  let [data, setData] = useState([FinancialStatementList]) // para pegar os dados do form e mandar para um data
  let [groupedData, setGroupedData] = useState([]) // para pegar os dados do data e transformar em um unico objeto

  const [quantidadeData, setQuantidadeData] = useState(0)
  const [quantidadeServ, setQuantidadeServ] = useState(0)

  const handleFileChange = event => {
    FormUtilsUpload(
      event,
      notify,
      data,
      setData,
      changeStep,
      currentStep,
      pathname
    )
  }

  const handleFieldsUpdateAndRedirect = (key, e, index) => {
    handleFieldsUpdate(data, key, e, index, setData)
  }

  const updateFieldsHandlerAndRedirect = (key, e, index) => {
    updateFieldsHandler(data, key, e, index, setData)
  }

  const updateFieldHandlerAndRedirect = (key, e, index) => {
    updateFieldHandler(data, key, e, index, setData, FinancialStatementList)
  }


  const handleDownload = () => {
    FormUtilsDownload(data)
  }

  const deleteServFunc = index => {
    deleteServ(data, setData, index, notify)
  }

  const updateQuantServFunc = value => {
    updateQuantServ(
      data,
      setData,
      value,
      notify,
      quantidadeServ,
      groupedData,
      FinancialStatementList,
      setQuantidadeServ,
      setGroupedData
    )
  }

  useEffect(() => {
    setQuantidadeServ(Object.keys(groupedData).length)
  }, [groupedData])

  useEffect(() => {
    if (
      data.length === null ||
      data.length === 0 ||
      data.length === undefined
    ) {
      if (currentStep === 1) {
        changeStep(currentStep - 1)
      } else {
        changeStep(currentStep - 2)
      }
      setData([FinancialStatementList])
    }
    
    
    setQuantidadeData(data.length)
    const newData = {}
    data.forEach(FinancialStatementList => {
      const ItemId = FinancialStatementList.ItemId
      if (!newData[ItemId]) {
        newData[ItemId] = [FinancialStatementList]
      } else {
        newData[ItemId].push(FinancialStatementList)
      }
    })
    setGroupedData(newData)
  }, [data])

  //https://stackoverflow.com/questions/61707105/react-app-upload-and-read-json-file-into-variable-without-a-server

  const formComponents = [
    <UserFormOV
      data={data}
      updateFieldHandler={updateFieldHandlerAndRedirect}
      updateQuantServ={updateQuantServFunc}
      handleFileChange={handleFileChange}
      quantidadeData={quantidadeData}
      quantidadeServ={quantidadeServ}
    />,
    <CardServico
      deleteServ={deleteServFunc}
      handleFieldsUpdate={handleFieldsUpdateAndRedirect}
      data={data}
      notify={notify}
      groupedData={groupedData}
      quantidadeData={quantidadeData}
      quantidadeServ={quantidadeServ}
      updateQuantServ={updateQuantServFunc}
      updateFieldHandler={updateFieldHandlerAndRedirect}
      updateFieldsHandler={updateFieldsHandlerAndRedirect}
    />,
    <TableJSON data={data} updateFieldHandler={updateFieldHandlerAndRedirect} notify={notify} setData={setData}/>
  ]

  const { currentStep, changeStep, isLastStep, currentComponent } =
    useForm(formComponents)
  return (
    <div>
      <div className='header'>
        <h2>Criação de JSON de Ficha Financeira</h2>
        <p>
          Especifique a unidade, matricula ou suba um JSON para agilizar o
          processo.
        </p>
      </div>

      <div className='form-container'>
        <form onSubmit={e => changeStep(currentStep + 1, e)}>
          <div className='inputs-container'>{currentComponent}</div>
          <div className='actions'>
            <button type='button' onClick={() => changeStep(currentStep - 1)}>
              <span>Voltar</span>
            </button>
            {!isLastStep ? (
              <button
                type='submit'
                disabled={!quantidadeData || quantidadeData === '0'}
              >
                <span>Avançar</span>
              </button>
            ) : (
              <button type='button' onClick={() => handleDownload()}>
                <span>Download JSON</span>
              </button>
            )}
          </div>
          <ToastContainer />
        </form>
      </div>
    </div>
  )
}

export default Ficha
