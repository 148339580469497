export function handleFieldsUpdate(data, key, e, index, setData) {
  String.prototype.replaceAt = function (index, replacement) {
    if (index >= this.length) {
      return this.valueOf()
    }
    return this.substring(0, index) + replacement + this.substring(index + 1)
  }
  let cont = 0
  if (data[0].hasOwnProperty('itemId')) {
    data.map((item, i) => {
      if (item.itemId === data[index].itemId) {
        cont++
      }
      return cont
    })
  } else {
    data.map((item, i) => {
      if (item.ItemId === data[index].ItemId) {
        cont++
      }
      return cont
    })
  }
  if (key === null) {
    return 'null'
  } else if (
    key === 'CashdiscDueDate' ||
    key === 'MovementDate' ||
    key === 'TransDate' ||
    key === 'invoiceDate' ||
    key === 'StartSchoolYear' ||
    key === 'cashDiscDate'
  ) {
    if (e === '') {
      e = '0001-01-01'
    }
    let tratarDate = e + 'T12:00:00'
    updateFieldsHandler(data, key, tratarDate, index, setData)
  } else if (key === 'DeliveryDate' || key === 'DueDate' || key === 'dueDate') {
    if (e === '') {
      e = '0001-01-01'
      let tratarDate = e + 'T12:00:00'
      updateFieldsHandler(data, key, tratarDate, index, setData)
      return
    }
    let tratarMes = e.slice(5, 7)
    tratarMes = parseInt(tratarMes)
    let tratarDate = e + 'T12:00:00'
    let Year = tratarDate.slice(3, 4)
    Year = parseInt(Year)
    for (let i = 0; i < cont; i++) {
      if (tratarMes + i < 10) {
        tratarDate = tratarDate.replaceAt(6, tratarMes + i)
        updateFieldHandler(data, key, tratarDate, index + i, setData)
      } else if (tratarMes + i <= 12) {
        tratarDate = tratarDate.replaceAt(5, '')
        tratarDate = tratarDate.replaceAt(5, tratarMes + i)
        updateFieldHandler(data, key, tratarDate, index + i, setData)
      } else {
        tratarDate = tratarDate.replaceAt(5, '0')
        tratarDate = tratarDate.replaceAt(6, tratarMes + i - 12)
        tratarDate = tratarDate.replaceAt(3, Year + 1)
        updateFieldHandler(data, key, tratarDate, index + i, setData)
      }

    }
  } else if (key === 'Month') {
    for (let i = 0; i < cont; i++) {
      if (parseInt(e) + i > 12) {
        updateFieldHandler(data, key, parseInt(e) + i - 12, index + i, setData)
        if (data[index + i].Year) {
          updateFieldHandler(
            data,
            'Year',
            parseInt(data[index].Year) + 1,
            index + i,
            setData
          )
        }
      } else {
        updateFieldHandler(data, key, parseInt(e) + i, index + i, setData)
        if (data[index + i].Year) {
          updateFieldHandler(
            data,
            'Year',
            parseInt(data[index].Year),
            index + i,
            setData
          )
        }
      }
    }
  } else if (key === 'Installment' || key === 'installmentNum' || key === 'InstallmentNum') {
    for (let i = 0; i < cont; i++) {
      updateFieldHandler(data, key, parseInt(e) + i, index + i, setData)
    }
  } else {
    updateFieldsHandler(data, key, e, index, setData)
  }
}

export function updateFieldsHandler(data, key, value, index, setData) {
  let ItemId
  if (data[0].hasOwnProperty('itemId')) {
    ItemId = data[index].itemId
  } else {
    ItemId = data[index].ItemId
  }
  if (key === 'tipoServico' || key === 'tipoTransacao') {
    setData((prevState) => {
      const newData = [...prevState]
      newData.map((item) => {
        if (item.itemId === ItemId) {
          item[key] = value
        }
        return item
      })
      return newData
    })
    return
  }
  if (key === 'numParcelas') {
    let cont = 0
    if (value < data[index].numParcelas) {
      // Remove as parcelas
      if (data[0].hasOwnProperty('itemId')) {
        data.map((item, i) => {
          if (item.itemId === ItemId) {
            //verifica o ultimo data do servico
            return {
              ...item,
              [key]: value
            }
          } else {
            cont++
            //o total é 16 e 10 não são, ele entrou com 4, o cont é 10, ele quer que seja 12
            return item
          }
        })
      } else {
        data.map((item, i) => {
          if (item.ItemId === ItemId) {
            //verifica o ultimo data do servico
            return {
              ...item,
              [key]: value
            }
          } else {
            cont++
            //o total é 16 e 10 não são, ele entrou com 4, o cont é 10, ele quer que seja 12
            return item
          }
        })
      }

      const valueInt = parseInt(value)
      const valorParada = cont + valueInt
      if (data[0].hasOwnProperty('itemId')) {
        if (data[data.length - 1].itemId === ItemId) {
          let newData = [...data]
          for (let i = data.length - 1; i >= valorParada; i--) {
            newData.splice(i, 1)
          }
          //acabou a remoção
          setData(newData)
          return
        } else {
          cont = 0
          let newData = data.map((item, i) => {
            if (item.itemId === ItemId) {
              //verifica o ultimo data do servico
              cont++
              return {
                ...item,
                [key]: value
              }
            } else {
              return item
            }
          })
          //realiza a remoção
          let cont2 = 0
          let cont3 = 0
          data.map((item, i) => {
            if (item.itemId === ItemId) {
              cont2++
              if (cont2 > value) {
                newData.splice(i - cont3, 1)
                cont3++
              }
            }
            return item
          })
          setData(newData)
          return
        }
      } else {
        if (data[data.length - 1].ItemId === ItemId) {
          let newData = [...data]
          for (let i = data.length - 1; i >= valorParada; i--) {
            newData.splice(i, 1)
          }
          //acabou a remoção
          setData(newData)
          return
        } else {
          cont = 0
          let newData = data.map((item, i) => {
            if (item.ItemId === ItemId) {
              //verifica o ultimo data do servico
              cont++
              return {
                ...item,
                [key]: value
              }
            } else {
              return item
            }
          })
          //realiza a remoção
          let cont2 = 0
          let cont3 = 0
          data.map((item, i) => {
            if (item.ItemId === ItemId) {
              cont2++
              if (cont2 > value) {
                newData.splice(i - cont3, 1)
                cont3++
              }
            }
            return item
          })
          setData(newData)
          return
        }
      }

      // Fim da remoção
    } else {
      // Adiciona as parcelas
      if (data[0].hasOwnProperty('itemId')) {
        setData(prev => {
          cont = 0

          const newData = prev.map((item, i) => {
            if (item.itemId === ItemId) {
              cont++
              //verificando a posição do ultimo data do servico
              return {
                ...item,
                [key]: value
              }
            } else {
              return {
                ...item,
                [key]: value
              }
            }
          })

          //ele verifica e agora começa o calculo das parcelas
          let cont2 = 0
          if (cont < value) {
            for (let i = cont; i < value; i++) {
              let newDataItem = {
                ...data[index],
                [key]: value,
                numParcelas: value
              }
              cont2++
              newData.push(newDataItem)
            }
            if (index === data.length - 1) {
              //caso seja o ultimo servico
            } else {
              let datasAlterar = data.length - index - 1
              //caso esteja em outro lugar
              for (let i = 0; i < datasAlterar; i++) {
                const posicao = index + 1
                let element = newData[posicao] // ele pega data[5] pq é o proximo servico
                const newIndex = data.length + cont2
                newData.splice(posicao, 1) // Remove o elemento da posição original data[5]
                newData.splice(newIndex, 0, element) // Insere o elemento na nova posição data[13]
              }
            }
          }
          let newValue

          if (data[0].hasOwnProperty('RefCustSettlement')) {
            for (let i = 0; i < cont2 + cont; i++) {
              newValue = newData[index].Installment
              newData[index + i].Installment = parseInt(newValue) + parseInt(i)
            }
            updateInputsDates(data, index, setData, cont2, 'DueDate', newData)
          } else if (data[0].hasOwnProperty('GrossAmount')) {
            for (let i = 0; i < cont2 + cont; i++) {
              newValue = newData[index].Month
              if (parseInt(newValue) + i > 12) {
                newData[index + i].Month = parseInt(newValue) + parseInt(i) - 12
                if (newData[index + i].Year) {
                  newData[index + i].Year = parseInt(newData[index].Year) + 1
                }
              } else {
                newData[index + i].Month = parseInt(newValue) + parseInt(i)
              }
              newValue = newData[index].InstallmentNum
              newData[index + i].InstallmentNum = parseInt(newValue) + parseInt(i)
            }
            updateInputsDates(data, index, setData, cont2, 'DeliveryDate', newData, cont)
          } else {
            for (let i = 0; i < cont2 + cont; i++) {
              newValue = newData[index].installmentNum
              newData[index + i].installmentNum = parseInt(newValue) + parseInt(i)
            }
            updateInputsDates(data, index, setData, cont2, 'dueDate', newData, cont)
          }
          return newData
        })
      } else {
        setData(prev => {
          cont = 0

          const newData = prev.map((item, i) => {
            if (item.ItemId === ItemId) {
              cont++
              //verificando a posição do ultimo data do servico
              return {
                ...item,
                [key]: value
              }
            } else {
              return {
                ...item,
                [key]: value
              }
            }
          })

          //ele verifica e agora começa o calculo das parcelas
          let cont2 = 0
          if (cont < value) {
            for (let i = cont; i < value; i++) {
              let newDataItem = {
                ...data[index],
                [key]: value,
                numParcelas: value
              }
              cont2++
              newData.push(newDataItem)
            }
            if (index === data.length - 1) {
              //caso seja o ultimo servico
            } else {
              let datasAlterar = data.length - index - 1
              //caso esteja em outro lugar
              for (let i = 0; i < datasAlterar; i++) {
                const posicao = index + 1
                let element = newData[posicao] // ele pega data[5] pq é o proximo servico
                const newIndex = data.length + cont2
                newData.splice(posicao, 1) // Remove o elemento da posição original data[5]
                newData.splice(newIndex, 0, element) // Insere o elemento na nova posição data[13]
              }
            }
          }
          let newValue

          if (data[0].hasOwnProperty('RefCustSettlement')) {
            for (let i = 0; i < cont2 + cont; i++) {
              newValue = newData[index].Installment
              newData[index + i].Installment = parseInt(newValue) + parseInt(i)
            }
            updateInputsDates(data, index, setData, cont2, 'DueDate', newData)
          } else if (data[0].hasOwnProperty('GrossAmount')) {
            for (let i = 0; i < cont2 + cont; i++) {
              newValue = newData[index].Month
              if (parseInt(newValue) + i > 12) {
                newData[index + i].Month = parseInt(newValue) + parseInt(i) - 12
                if (newData[index + i].Year) {
                  newData[index + i].Year = parseInt(newData[index].Year) + 1
                }
              } else {
                newData[index + i].Month = parseInt(newValue) + parseInt(i)
              }
              newValue = newData[index].InstallmentNum
              newData[index + i].InstallmentNum = parseInt(newValue) + parseInt(i)
            }
            updateInputsDates(data, index, setData, cont2, 'DeliveryDate', newData, cont)
          } else {
            for (let i = 0; i < cont2 + cont; i++) {
              newValue = newData[index].installmentNum
              newData[index + i].installmentNum = parseInt(newValue) + parseInt(i)
            }
            updateInputsDates(data, index, setData, cont2, 'dueDate', newData, cont)
          }
          return newData
        })
      }

    }
  } else {
    if (data[0].hasOwnProperty('itemId')) {
      setData(prev => {
        const newData = prev.map((item, i) => {
          if (item.itemId === ItemId) {
            return {
              ...item,
              [key]: value
            }
          } else {
            return item
          }
        })
        return newData
      })
    } else {
      setData(prev => {
        const newData = prev.map((item, i) => {
          if (item.ItemId === ItemId) {
            return {
              ...item,
              [key]: value
            }
          } else {
            return item
          }
        })
        return newData
      })
    }

  }
}
export function updateFieldHandler(
  data,
  key,
  value,
  index,
  setData,
  formTemplate
) {
  if (
    key === 'DeliveryDate' ||
    key === 'DueDate' ||
    key === 'dueDate' ||
    key === 'TransDate' ||
    key === 'CashdiscDueDate' ||
    key === 'MovementDate' ||
    key === 'invoiceDate' ||
    key === 'StartSchoolYear' ||
    key === 'cashDiscDate'
  ) {
    if (value === '') {
      value = '0001-01-01'
    }
    let e = value.slice(0, 10)
    value = e + 'T12:00:00'
    setData(prev => {
      const newData = [...prev]
      if (!newData[index]) {
        newData[index] = formTemplate
      }
      newData[index] = {
        ...newData[index],
        [key]: value
      }
      return newData
    })
  } else {
    setData(prev => {
      const newData = [...prev]
      if (!newData[index]) {
        newData[index] = formTemplate
      }
      newData[index] = {
        ...newData[index],
        [key]: value
      }
      return newData
    })
  }
}

function updateInputsDates(data, index, setData, contador, key, newData, cont) {
  let newValue = data[index][key].slice(0, 10)
  if (newValue === '') {
    return
  }
  let tratarMes = newValue.slice(5, 7)
  tratarMes = parseInt(tratarMes)
  let tratarDate = newValue + 'T12:00:00'
  let Year = tratarDate.slice(3, 4)
  Year = parseInt(Year)
  for (let i = 0; i < contador + cont; i++) {
    if (tratarMes + i < 10) {
      tratarDate = tratarDate.replaceAt(6, tratarMes + i)
      newData[index + i][key] = tratarDate
    } else if (tratarMes + i <= 12) {
      tratarDate = tratarDate.replaceAt(5, '')
      tratarDate = tratarDate.replaceAt(5, tratarMes + i)
      newData[index + i][key] = tratarDate
    } else {
      tratarDate = tratarDate.replaceAt(5, '0')
      tratarDate = tratarDate.replaceAt(6, tratarMes + i - 12)
      tratarDate = tratarDate.replaceAt(3, Year + 1)
      newData[index + i][key] = tratarDate
    }
  }
}
