import './inputs.css'
const UserFormInpTransacao = ({
  data,
  updateFieldHandler,
  updateFieldsHandler,
  handleModalOpen,
  handleFieldsUpdate,
  index
}) => {
  let adjustDate = []
  let adjustDate2 = []
  let adjustDate3 = []
  data.map((item, i) => {
    if (item.dueDate) {
      const dueDate = item.dueDate.slice(0, 10)
      adjustDate[i] = {
        dueDate: dueDate
      }
    }
    if (item.invoiceDate) {
      const invoiceDate = item.invoiceDate.slice(0, 10)
      adjustDate2[i] = {
        invoiceDate: invoiceDate
      }
    } if (item.cashDiscDate) {
      const cashDiscDate = item.cashDiscDate.slice(0, 10)
      adjustDate3[i] = {
        cashDiscDate: cashDiscDate
      }
    }
    return null
  })

  const handleClickInside = e => {
    e.stopPropagation() // Impede a propagação do evento
  }

  return (
    <div className='form-container-input' onClick={handleClickInside}>
      <div className='closemodalSty'></div>
      <div className='form-control formTitulo'>
        <div className='Titulo'>{data[index]?.itemId} </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='bankAccount'>bankAccount:</label>
            <label htmlFor='bankAccount' className='label-help'>Conta Bancária:</label>
          </div>
          <input
            type='text'
            name='bankAccount'
            id='bankAccount'
            placeholder='Digite o bankAccount'
            value={data[index]?.bankAccount || 'null'}
            onChange={e =>
              handleFieldsUpdate('bankAccount', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='bankingBillet'>bankingBillet:</label>
            <label htmlFor='bankingBillet' className='label-help'>Boleto Bancário:</label>
          </div>
          <input
            type='text'
            name='bankingBillet'
            id='bankingBillet'
            placeholder='Digite o bankingBillet'
            value={data[index]?.bankingBillet || 'null'}
            onChange={e =>
              handleFieldsUpdate('bankingBillet', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='custCard'>custCard:</label>
            <label htmlFor='custCard' className='label-help'>...:</label>
          </div>
          <input
            type='text'
            name='custCard'
            id='custCard'
            placeholder='Digite o custCard'
            value={data[index]?.custCard || 'null'}
            onChange={e =>
              handleFieldsUpdate('custCard', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='transAmountCur'>transAmountCur:</label>
            <label htmlFor='transAmountCur' className='label-help'>Valor de Transação Atual:</label>
          </div>
          <input
            type='number'
            name='transAmountCur'
            id='transAmountCur'
            placeholder='Digite o transAmountCur'
            value={data[index]?.transAmountCur || ''}
            onChange={e =>
              handleFieldsUpdate('transAmountCur', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='dueDate'>dueDate:</label>
            <label htmlFor='dueDate' className='label-help'>Data de Vencimento:</label>
          </div>
          <input
            type='date'
            name='dueDate'
            id='dueDate'
            placeholder='Enter dueDate'
            value={adjustDate[index]?.dueDate || ''}
            onChange={e => handleFieldsUpdate('dueDate', e.target.value, index)}
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='invoiceId'>invoiceId:</label>
            <label htmlFor='invoiceId' className='label-help'>Matrícula e Parcela:</label>
          </div>
          <input
            type='text'
            name='invoiceId'
            id='invoiceId'
            placeholder='Enter invoiceId'
            value={data[index]?.invoiceId || ''}
            onChange={e => handleFieldsUpdate('invoiceId', e.target.value, index)}
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='cashDiscAmount'>cashDiscAmount:</label>
            <label htmlFor='cashDiscAmount' className='label-help'>Valor do Desconto:</label>
          </div>
          <input
            type='number'
            name='cashDiscAmount'
            id='cashDiscAmount'
            placeholder='Digite o cashDiscAmount'
            value={data[index]?.cashDiscAmount || ''}
            onChange={e =>
              handleFieldsUpdate('cashDiscAmount', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='cashDiscDate'>cashDiscDate:</label>
            <label htmlFor='cashDiscDate' className='label-help'>Data do Desconto:</label>
          </div>
          <input
            type='date'
            name='cashDiscDate'
            id='cashDiscDate'
            placeholder='Digite o cashDiscDate'
            value={adjustDate3[index]?.cashDiscDate || ''}
            onChange={e =>
              handleFieldsUpdate('cashDiscDate', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='configId'>configId:</label>
            <label htmlFor='configId' className='label-help'>Configuração:</label>
          </div>
          <select
            name='configId'
            id='configId'
            value={data[index]?.configId}
            onChange={e =>
              handleFieldsUpdate('configId', e.target.value, index)
            }
          >
            <option value='MENSALIDADE'>MENSALIDADE</option>
            <option value='1ª PARCELA'>1ª PARCELA</option>
          </select>
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='installmentNum'>installmentNum:</label>
            <label htmlFor='installmentNum' className='label-help'>...:</label>
          </div>
          <input
            type='number'
            name='installmentNum'
            id='installmentNum'
            placeholder='Digite o installmentNum'
            value={data[index]?.installmentNum || ''}
            onChange={e =>
              handleFieldsUpdate('installmentNum', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='invoiceDate'>invoiceDate:</label>
            <label htmlFor='invoiceDate' className='label-help'>Data da Fatura:</label>
          </div>
          <input
            type='date'
            name='invoiceDate'
            id='invoiceDate'
            placeholder='Digite o invoiceDate'
            value={adjustDate2[index]?.invoiceDate || ''}
            onChange={e =>
              handleFieldsUpdate('invoiceDate', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='offsetAccountNum'>offsetAccountNum:</label>
            <label htmlFor='offsetAccountNum' className='label-help'>...:</label>
          </div>
          <input
            type='text'
            name='offsetAccountNum'
            id='offsetAccountNum'
            placeholder='Digite o offsetAccountNum'
            value={data[index]?.offsetAccountNum || ''}
            onChange={e =>
              handleFieldsUpdate('offsetAccountNum', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='offSetAccountType'>offSetAccountType:</label>
            <label htmlFor='offSetAccountType' className='label-help'>...:</label>
          </div>
          <input
            type='number'
            name='offSetAccountType'
            id='offSetAccountType'
            placeholder='Digite o offSetAccountType'
            value={data[index]?.offSetAccountType || ''}
            onChange={e =>
              handleFieldsUpdate('offSetAccountType', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='paymMode'>paymMode:</label>
            <label htmlFor='paymMode' className='label-help'>Modo de Pagamento:</label>
          </div>
          <input
            type='text'
            name='paymMode'
            id='paymMode'
            placeholder='Digite o paymMode'
            value={data[index]?.paymMode || ''}
            onChange={e =>
              handleFieldsUpdate('paymMode', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='paymSpec'>paymSpec:</label>
            <label htmlFor='paymSpec' className='label-help'>...:</label>
          </div>
          <input
            type='text'
            name='paymSpec'
            id='paymSpec'
            placeholder='Digite o paymSpec'
            value={data[index]?.paymSpec || ''}
            onChange={e =>
              handleFieldsUpdate('paymSpec', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='paymTermId'>paymTermId:</label>
            <label htmlFor='paymTermId' className='label-help'>...:</label>
          </div>
          <input
            type='text'
            name='paymTermId'
            id='paymTermId'
            placeholder='Digite o paymTermId'
            value={data[index]?.paymTermId || ''}
            onChange={e =>
              handleFieldsUpdate('paymTermId', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='qtyOfInstallments'>qtyOfInstallments:</label>
            <label htmlFor='qtyOfInstallments' className='label-help'>Quantidade de Faturas:</label>
          </div>
          <input
            type='number'
            name='qtyOfInstallments'
            id='qtyOfInstallments'
            placeholder='Digite o qtyOfInstallments'
            value={data[index]?.qtyOfInstallments || ''}
            onChange={e =>
              handleFieldsUpdate('qtyOfInstallments', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='transTxt'>transTxt:</label>
            <label htmlFor='transTxt' className='label-help'>...:</label>
          </div>
          <input
            type='text'
            name='transTxt'
            id='transTxt'
            placeholder='Digite o transTxt'
            value={data[index]?.transTxt || ''}
            onChange={e =>
              handleFieldsUpdate('transTxt', e.target.value, index)
            }
          />
        </div>
      </div>
    </div>
  )
}

export default UserFormInpTransacao
