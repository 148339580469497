function handleFileChange(
  event,
  notify,
  data,
  setData,
  changeStep,
  currentStep,
  pathname
) {
  const file = event.target.files[0]
  if (file && file.name) {
    const extension = file.name.split('.').pop().toLowerCase()
    if (extension !== 'json' && extension !== 'txt') {
      notify(
        'Formato de arquivo inválido! Apenas arquivos JSON são permitidos.'
      )
      return
    }
    handleJsonUpload(
      file,
      data,
      setData,
      changeStep,
      currentStep,
      notify,
      pathname
    )
  } else {
    notify('Arquivo não encontrado!')
  }
}
function handleJsonUpload(
  file,
  data,
  setData,
  changeStep,
  currentStep,
  notify,
  pathname
) {
  const reader = new FileReader()
  reader.readAsText(file)
  reader.onload = () => {
    const jsonData = JSON.parse(reader.result)._contract
    let jsonDataAreaId = jsonData.DataAreaId
    let jsonDataMatriculaId = jsonData.MatriculaId
    let tipoTransacao
    let tipoServico
    if (pathname === '/' && jsonData.SalesOrderList) {
      setData(jsonData.SalesOrderList)
      notify('Json de OV!', 'success')
    } else if (pathname === '/' && !jsonData.SalesOrderList) {
      notify('não é um Json de OV')
      return
    } else if (pathname === '/ficha' && jsonData.FinancialStatementList) {
      setData(prev => {
        let newData = [...prev]
        newData = jsonData.FinancialStatementList
        newData[0].FiscalEstablishmentId = jsonData.FiscalEstablishmentId
        return newData
      })
      notify('Json de Ficha Financeira!', 'success')
    } else if (pathname === '/ficha' && !jsonData.FinancialStatementList) {
      notify('não é um Json de Ficha Financeira')
      return
    } else if (pathname === '/transacoes' && jsonData.financialTransDetail) {
      notify('Json de Transações Financeiras!', 'success')
      const path = jsonData.financialTransDetail.transactions
      let newData = []
      if (path.material.firstIntallment != null) {
        path.material.firstInstallment.forEach(item => {
          item.tipoServico = tipoServico
          item.tipoTransacao = tipoTransacao
          newData.push(item)
        })
      } 
      if (path.material.monthlyPayment != null) {
        path.material.monthlyPayment.forEach(item => {
          item.tipoServico = 'material'
          item.tipoTransacao = 'monthlyPayment'
          newData.push(item)
        })
      } 
      if (path.service.firstIntallment != null) {
        path.service.firstIntallment.forEach(item => {
          item.tipoServico = 'service'
          item.tipoTransacao = 'firstIntallment'
          newData.push(item)
        })
      } 
      if (path.service.monthlyPayment != null) {
        path.service.monthlyPayment.forEach(item => {
          item.tipoServico = 'service'
          item.tipoTransacao = 'monthlyPayment'
          newData.push(item)
        })
      } 
      if (path.upSelling != null) {
        path.upSelling.forEach(item => {
          item.tipoServico = 'upSelling'
          item.tipoTransacao = 'upSelling'
          newData.push(item)
        })
      } 
      if (path.feesEvents != null) {
        path.feesEvents.forEach(item => {
          item.tipoServico = 'feesEvents'
          item.tipoTransacao = 'feesEvents'
          newData.push(item)
        })
      }
      newData[0].FiscalEstablishmentId = jsonData.FiscalEstablishmentId
      newData[0].custAccount = jsonData.financialTransDetail.custAccount
      newData[0].transStatus = jsonData.financialTransDetail.transStatus
      setData(newData)
    } else if (pathname === '/transacoes' && !jsonData.financialTransDetail) {
      notify('Não é um Json de Transações Financeiras!')
      return
    }
    setData(prev => {
      const newData = [...prev]
      newData[0] = {
        ...newData[0],
        DataAreaId: jsonDataAreaId,
        MatriculaId: jsonDataMatriculaId
      }
      return newData
    })
    changeStep(currentStep + 1)
  }
}

export default handleFileChange
