// Components
import UserFormOV from '../components/forms/inputsIntroOV'
import TableJSONTransacao from '../components/tables/TableTransacao'
import CardTransacoes from '../components/card/CardTransacoes'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

//hooks
import { useForm } from '../hook/useForm'

//utils
import { handleFieldsUpdate, updateFieldHandler, updateFieldsHandler } from '../utils/FormUtilsOv'
import FormUtilsDownload from '../utils/FormUtilsJsonDownload'
import FormUtilsUpload from '../utils/FormUtilsJsonUpload'
import { deleteServ, updateQuantServ} from '../utils/FormUtilsUpdateServ'

// external components
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'

const financialTransDetail = {
  DataAreaId: '',
  FiscalEstablishmentId: '',
  MatriculaId: '',
  tipoTransacao: '',
  tipoServico: 'service',
  tipoTransacao: 'firstInstallment',
  financialMovType: '',
  custAccount: '',
  transStatus: '',
  bankAccount: 'null',
  bankingBillet: 'null',
  custCard: 'null',
  transAmountCur: '',
  dueDate: '',
  invoiceId: '',
  cashDiscAmount: '',
  cashDiscDate: '',
  configId: 'MENSALIDADE',
  installmentNum: '',
  invoiceDate: '',
  itemId: '',
  offsetAccountNum: '',
  offSetAccountType: '',
  paymMode: '',
  paymSpec: '',
  paymTermId: '',
  qtyOfInstallments: '',
  transTxt: ''
}

const notify = (mensagem, tipo) => {
  if (tipo === 'success') {
    toast.success(mensagem, {
      autoClose: 1500
    })
  } else {
    toast.warn(mensagem, {
      autoClose: 1500
    })
  }
}

function Transacoes () {
  const location = useLocation()
  const { pathname } = location

  let [data, setData] = useState([
    {
      ...financialTransDetail,
      tipoServico: 'service',
      tipoTransacao: 'firstInstallment'
    }
  ]) // para pegar os dados do form e mandar para um data
  let [groupedData, setGroupedData] = useState([]) // para pegar os dados do data e transformar em um unico objeto

  const [quantidadeData, setQuantidadeData] = useState(0)
  const [quantidadeServ, setQuantidadeServ] = useState(0)

  const handleFileChange = event => {
    FormUtilsUpload(
      event,
      notify,
      data,
      setData,
      changeStep,
      currentStep,
      pathname
    )
  }

  const handleFieldsUpdateAndRedirect = (key, e, index) => {
    handleFieldsUpdate(data, key, e, index, setData)
  }

  const updateFieldsHandlerAndRedirect = (key, e, index) => {
    updateFieldsHandler(data, key, e, index, setData)
  }

  const updateFieldHandlerAndRedirect = (key, e, index) => {
    updateFieldHandler(data, key, e, index, setData, financialTransDetail)
  }

  const handleDownload = () => {
    FormUtilsDownload(data)
  }

  const deleteServFunc = index => {
    deleteServ(data, setData, index, notify)
  }

  const updateQuantServFunc = value => {
    updateQuantServ(
      data,
      setData,
      value,
      notify,
      quantidadeServ,
      groupedData,
      financialTransDetail,
      setQuantidadeServ,
      setGroupedData
    )
  }

  useEffect(() => {
    setQuantidadeServ(Object.keys(groupedData).length)
  }, [groupedData])

  useEffect(() => {
    if (
      data.length === null ||
      data.length === 0 ||
      data.length === undefined
    ) {
      if (currentStep === 1) {
        changeStep(currentStep - 1)
      } else {
        changeStep(currentStep - 2)
      }
      setData([financialTransDetail])
    }
    setQuantidadeData(data.length)
    const newData = {}
    data.forEach(financialTransDetail => {
      const itemId = financialTransDetail.itemId
      if (!newData[itemId]) {
        newData[itemId] = [financialTransDetail]
      } else {
        newData[itemId].push(financialTransDetail)
      }
    })
    setGroupedData(newData)
  }, [data])


  const formComponents = [
    <UserFormOV
      data={data}
      updateFieldHandler={updateFieldHandlerAndRedirect}
      updateQuantServ={updateQuantServFunc}
      handleFileChange={handleFileChange}
      quantidadeData={quantidadeData}
      quantidadeServ={quantidadeServ}
    />,
    <CardTransacoes
      deleteServ={deleteServFunc}
      handleFieldsUpdate={handleFieldsUpdateAndRedirect}
      data={data}
      groupedData={groupedData}
      quantidadeData={quantidadeData}
      quantidadeServ={quantidadeServ}
      updateQuantServ={updateQuantServFunc}
      updateFieldHandler={updateFieldHandlerAndRedirect}
      updateFieldsHandler={updateFieldsHandlerAndRedirect}
    />,
    <TableJSONTransacao data={data} updateFieldHandler={updateFieldHandlerAndRedirect} notify={notify} setData={setData}/>
  ]

  const { currentStep, changeStep, isLastStep, currentComponent } =
    useForm(formComponents)
  return (
    <div>
      <div className='header'>
        <h2 className='título-header'>Criação de JSON de Transações Financeira</h2>
        <p>
          Especifique a unidade, matricula ou suba um JSON para agilizar o
          processo.
        </p>
      </div>
      <div className='form-container'>
        <form onSubmit={e => changeStep(currentStep + 1, e)}>
          <div className='inputs-container'>{currentComponent}</div>
          <div className='actions'>
            <button type='button' onClick={() => changeStep(currentStep - 1)}>
              <span>Voltar</span>
            </button>
            {!isLastStep ? (
              <button
                type='submit'
                disabled={!quantidadeData || quantidadeData === '0'}
              >
                <span>Avançar</span>
              </button>
            ) : (
              <button type='button' onClick={() => handleDownload()}>
                <span>Download JSON</span>
              </button>
            )}
          </div>
          <ToastContainer />
        </form>
      </div>
    </div>
  )
}

export default Transacoes
