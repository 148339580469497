import './inputs.css'

const UserFormFichaInp = ({
  data,
  updateFieldHandler,
  updateFieldsHandler,
  handleModalOpen,
  handleFieldsUpdate,
  index
}) => {
  let adjustCashdiscDueDate = []
  let adjustDueDate = []
  let adjustTransDate = []
  let adjustMovementDate = []
  data.map((item, i) => {
    if (item.CashdiscDueDate) {
      const cashediscDueDate = item.CashdiscDueDate.slice(0, 10)
      adjustCashdiscDueDate[i] = {
        CashdiscDueDate: cashediscDueDate
      }
    }
    if (item.DueDate) {
      const dueDate = item.DueDate.slice(0, 10)
      adjustDueDate[i] = {
        DueDate: dueDate
      }
    }
    if (item.TransDate) {
      const transDate = item.TransDate.slice(0, 10)
      adjustTransDate[i] = {
        TransDate: transDate
      }
    }
    if (item.MovementDate) {
      const movementDate = item.MovementDate.slice(0, 10)
      adjustMovementDate[i] = {
        MovementDate: movementDate
      }
    }
    return null
  })
  const handleClickInside = (e) => {
    e.stopPropagation(); // Impede a propagação do evento
  };
  return (
    <div className='form-container-input' onClick={handleClickInside}>
      <div className='closemodalSty'>
      </div>
      <div className='form-control formTitulo'>
        <div className='Titulo'>{data[index]?.ItemId} </div>
      </div>
      <div className='form-control'>
        <div>
        <div className='label-container'>
              <label htmlFor='InstallmentAmountCur'>InstallmentAmountCur:</label>
              <label htmlFor='InstallmentAmountCur' className='label-help'>Desconto na Parcela:</label>
            </div>
          <input
            type='number'
            name='InstallmentAmountCur'
            id='InstallmentAmountCur'
            placeholder='Digite o InstallmentAmountCur'
            value={data[index]?.InstallmentAmountCur || ''}
            onChange={e =>
              handleFieldsUpdate('InstallmentAmountCur', e.target.value, index)
            }
          />
        </div>
        <div>
        <div className='label-container'>
              <label htmlFor='InstallmentNetAmount'>InstallmentNetAmount:</label>
              <label htmlFor='InstallmentNetAmount' className='label-help'>Valor líquido da Parcela:</label>
            </div>
          <input
            type='number'
            name='InstallmentNetAmount'
            id='InstallmentNetAmount'
            placeholder='Digite o InstallmentNetAmount'
            value={data[index]?.InstallmentNetAmount || ''}
            onChange={e =>
              handleFieldsUpdate('InstallmentNetAmount', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='PaymentMethod'>PaymentMethod:</label>
              <label htmlFor='PaymentMethod' className='label-help'>Método de Pagamento:</label>
            </div>
          <input
            type='text'
            name='PaymentMethod'
            id='PaymentMethod'
            placeholder='Digite o valor PaymentMethod'
            value={data[index]?.PaymentMethod || ''}
            onChange={e =>
              handleFieldsUpdate('PaymentMethod', e.target.value, index)
            }
          />
        </div>
        <div>
        <div className='label-container'>
              <label htmlFor='TotalDiscount'>TotalDiscount:</label>
              <label htmlFor='TotalDiscount' className='label-help'>Desconto Total:</label>
            </div>
          <input
            type='number'
            name='TotalDiscount'
            id='TotalDiscount'
            placeholder='Digite o valor do TotalDiscount'
            value={data[index]?.TotalDiscount || ''}
            onChange={e =>
              handleFieldsUpdate('TotalDiscount', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
        <div className='label-container'>
              <label htmlFor='CashdiscDueDate'>CashdiscDueDate:</label>
              <label htmlFor='CashdiscDueDate' className='label-help'>Data do Desconto:</label>
            </div>
          <input
            type='date'
            name='CashdiscDueDate'
            id='CashdiscDueDate'
            placeholder='Digite o CashdiscDueDate'
            value={adjustCashdiscDueDate[index]?.CashdiscDueDate || ''}
            onChange={e =>
              handleFieldsUpdate('CashdiscDueDate', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='CashDiscValue'>CashDiscValue:</label>
              <label htmlFor='CashDiscValue' className='label-help'>Desconto em dinheiro:</label>
            </div>
          <input
            type='number'
            name='CashDiscValue'
            id='CashDiscValue'
            placeholder='Digite o CashDiscValue'
            value={data[index]?.CashDiscValue || ''}
            onChange={e =>
              handleFieldsUpdate('CashDiscValue', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='DueDate'>DueDate:</label>
              <label htmlFor='DueDate' className='label-help'>Data de Vencimento:</label>
            </div>
          <input
            type='date'
            name='DueDate'
            id='DueDate'
            placeholder='Digite o DueDate'
            value={adjustDueDate[index]?.DueDate || ''}
            onChange={e => handleFieldsUpdate('DueDate', e.target.value, index)}
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='InvoiceId'>InvoiceId:</label>
              <label htmlFor='InvoiceId' className='label-help'>ID da Fatura:</label>
            </div>
          <input
            type='text'
            name='InvoiceId'
            id='InvoiceId'
            placeholder='Digite o InvoiceId'
            value={data[index]?.InvoiceId || ''}
            onChange={e =>
              handleFieldsUpdate('InvoiceId', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='Installment'>Installment:</label>
              <label htmlFor='Installment' className='label-help'>Prestação n°:</label>
            </div>
          <input
            type='number'
            name='Installment'
            id='Installment'
            placeholder='Digite a Installment'
            value={data[index]?.Installment || ''}
            onChange={e =>
              handleFieldsUpdate('Installment', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='ConfigId'>ConfigId:</label>
              <label htmlFor='ConfigId' className='label-help'>Configuração:</label>
            </div>
          <select

            name='configId'
            id='configId'
            value={data[index]?.ConfigId}
            onChange={e =>
              handleFieldsUpdate('ConfigId', e.target.value, index)
            }
          >
            <option value='MENSALIDADE'>MENSALIDADE</option>
            <option value='1ª PARCELA'>1ª PARCELA</option>
          </select>
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='Responsible'>Responsible:</label>
              <label htmlFor='Responsible' className='label-help'>Responsável:</label>
            </div>
          <input
            type='text'
            name='Responsible'
            id='Responsible'
            placeholder='Digite o Responsible'
            value={data[index]?.Responsible || ''}
            onChange={e =>
              handleFieldsUpdate('Responsible', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='TransDate'>TransDate:</label>
              <label htmlFor='TransDate' className='label-help'>Data da Transação:</label>
            </div>
          <input
            type='date'
            name='TransDate'
            id='TransDate'
            placeholder='Digite o TransDate'
            value={adjustTransDate[index]?.TransDate || ''}
            onChange={e =>
              handleFieldsUpdate('TransDate', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='ReserveValue'>ReserveValue:</label>
              <label htmlFor='ReserveValue' className='label-help'>Valor de Reserva:</label>
            </div>
          <input
            type='number'
            name='ReserveValue'
            id='ReserveValue'
            placeholder='Digite o ReserveValue'
            value={data[index]?.ReserveValue || ''}
            onChange={e =>
              handleFieldsUpdate('ReserveValue', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='ScholarshipValue'>ScholarshipValue:</label>
              <label htmlFor='ScholarshipValue' className='label-help'>Valor de Bolsa:</label>
            </div>
          <input
            type='number'
            name='ScholarshipValue'
            id='ScholarshipValue'
            placeholder='Digite o tipo de ScholarshipValue'
            value={data[index]?.ScholarshipValue || ''}
            onChange={e =>
              handleFieldsUpdate('ScholarshipValue', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='PaymMethodDiscAmount'>PaymMethodDiscAmount:</label>
              <label htmlFor='PaymMethodDiscAmount' className='label-help'>Desconto do Método:</label>
            </div>
          <input
            type='number'
            name='PaymMethodDiscAmount'
            id='PaymMethodDiscAmount'
            placeholder='Digite o PaymMethodDiscAmount'
            value={data[index]?.PaymMethodDiscAmount || ''}
            onChange={e =>
              handleFieldsUpdate('PaymMethodDiscAmount', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='TransactionType'>TransactionType:</label>
              <label htmlFor='TransactionType' className='label-help'>Tipo de Transação:</label>
            </div>
          <input
            type='number'
            name='TransactionType'
            id='TransactionType'
            placeholder='Digite o TransactionType'
            value={data[index]?.TransactionType || ''}
            onChange={e =>
              handleFieldsUpdate('TransactionType', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='TypeOfMovement'>TypeOfMovement:</label>
              <label htmlFor='TypeOfMovement' className='label-help'>Tipo de Movimentação:</label>
            </div>
          <input
            type='number'
            name='TypeOfMovement'
            id='TypeOfMovement'
            placeholder='Digite o TypeOfMovement'
            value={data[index]?.TypeOfMovement || ''}
            onChange={e =>
              handleFieldsUpdate('TypeOfMovement', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='MovementDate'>MovementDate:</label>
              <label htmlFor='MovementDate' className='label-help'>Data da Movimentação:</label>
            </div>
          <input
            type='date'
            name='MovementDate'
            id='MovementDate'
            placeholder='Enter Delivery Date'
            value={adjustMovementDate[index]?.MovementDate || ''}
            onChange={e =>
              handleFieldsUpdate('MovementDate', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='MatriculaId'>MatriculaId:</label>
              <label htmlFor='MatriculaId' className='label-help'>Matrícula do Aluno:</label>
            </div>
          <input
            type='text'
            name='MatriculaId'
            id='MatriculaId'
            placeholder='Digite a MatriculaId'
            value={data[index]?.MatriculaId || ''}
            onChange={e =>
              handleFieldsUpdate('MatriculaId', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='PaymentStatus'>PaymentStatus:</label>
              <label htmlFor='PaymentStatus' className='label-help'>Status de Pagamento:</label>
            </div>
          <input
            type='number'
            name='PaymentStatus'
            id='PaymentStatus'
            placeholder='Digite o PaymentStatus'
            value={data[index]?.PaymentStatus || ''}
            onChange={e =>
              handleFieldsUpdate('PaymentStatus', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
              <label htmlFor='AmountPaid'>AmountPaid:</label>
              <label htmlFor='AmountPaid' className='label-help'>Valor Pago:</label>
            </div>
          <input
            type='number'
            name='AmountPaid'
            id='AmountPaid'
            placeholder='Digite o AmountPaid'
            value={data[index]?.AmountPaid || ''}
            onChange={e =>
              handleFieldsUpdate('AmountPaid', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
              <label htmlFor='TypeOfPayment'>TypeOfPayment:</label>
              <label htmlFor='TypeOfPayment' className='label-help'>Tipo de Pagamento:</label>
            </div>
          <input
            type='number'
            name='TypeOfPayment'
            id='TypeOfPayment'
            placeholder='Digite o TypeOfPayment'
            value={data[index]?.TypeOfPayment || ''}
            onChange={e =>
              handleFieldsUpdate('TypeOfPayment', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
        <div className='label-container'>
              <label htmlFor='AgreementDocument'>AgreementDocument:</label>
              <label htmlFor='AgreementDocument' className='label-help'>Documento do Acordo:</label>
            </div>
          <input
            type='text'
            name='AgreementDocument'
            id='AgreementDocument'
            placeholder='Digete o AgreementDocument'
            value={data[index]?.AgreementDocument || 'null'}
            onChange={e =>
              handleFieldsUpdate('AgreementDocument', e.target.value, index)
            }
          />
        </div>
        <div>
        <div className='label-container'>
              <label htmlFor='RefCustTrans'>RefCustTrans:</label>
              <label htmlFor='RefCustTrans' className='label-help'>...:</label>
            </div>
          <input
            type='number'
            name='RefCustTrans'
            id='RefCustTrans'
            placeholder='Digite o RefCustTrans'
            value={data[index]?.RefCustTrans || ''}
            onChange={e =>
              handleFieldsUpdate('RefCustTrans', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
        <div className='label-container'>
              <label htmlFor='RefCustTransOpen'>RefCustTransOpen:</label>
              <label htmlFor='RefCustTransOpen' className='label-help'>...:</label>
            </div>
          <input
            type='number'
            name='RefCustTransOpen'
            id='RefCustTransOpen'
            placeholder='Digite o RefCustTransOpen'
            value={data[index]?.RefCustTransOpen || ''}
            onChange={e =>
              handleFieldsUpdate('RefCustTransOpen', e.target.value, index)
            }
          />
        </div>
        <div>
        <div className='label-container'>
              <label htmlFor='RefCustSettlement'>RefCustSettlement:</label>
              <label htmlFor='RefCustSettlement' className='label-help'>...:</label>
            </div>
          <input
            type='number'
            name='RefCustSettlement'
            id='RefCustSettlement'
            placeholder='Digite o RefCustSettlement'
            value={data[index]?.RefCustSettlement || ''}
            onChange={e =>
              handleFieldsUpdate('RefCustSettlement', e.target.value, index)
            }
          />
        </div>
      </div>
    </div>
  )
}

export default UserFormFichaInp
