import React from 'react'
import './inputs_intro.css'
import './inputs.css'

const UserFormOV = ({ data, updateFieldHandler, handleFileChange, quantidadeData, quantidadeServ, updateQuantServ }) => {
  let showFicha = false;
  let showTransacao = false;
  if (window.location.href.includes('ficha')) {
    showFicha = true;
  } else if (window.location.href.includes('transacoes')) {
    showTransacao = true;
  }
  return (
    <div>
      <div className='form-control' id="JSON">
        <label htmlFor='inputFile' >Clique aqui para subir o JSON:</label>
        <input className="input-file" id='inputFile' type='file' onChange={handleFileChange} />
        <label htmlFor='inputFile' className="input-file-trigger">Subir JSON:</label>
      </div>
      <div id='espace-control'>
        <hr></hr>
        <div>
          ou preencha os campos:
        </div>
        <hr></hr>
      </div>
      {showTransacao !== true && showFicha !== true ? (
        <div className='form-control'>
          <div>
            <div className='label-container'>
              <label htmlFor='DataAreaId'>DataAreaId:</label>
              <label htmlFor='DataAreaId' className='label-help'>Sigla da Entidade:</label>
            </div>
            <input
              type='text'
              name='DataAreaId'
              id='DataAreaId'
              placeholder='Digite a sigla da entidade'
              required
              value={data[0]?.DataAreaId || ''}
              onChange={e => updateFieldHandler('DataAreaId', e.target.value, 0)}
            />
          </div>

          <div>
            <div className='label-container'>
              <label htmlFor='MatriculaId'>MatriculaId:</label>
              <label htmlFor='MatriculaId' className='label-help'>Matrícula do Aluno:</label>
            </div>
            <input
              type='text'
              name='MatriculaId'
              id='MatriculaId'
              placeholder='Digite a matricula'
              required
              value={data[0].MatriculaId || ''}
              onChange={e => updateFieldHandler('MatriculaId', e.target.value, 0)}
            />
          </div>
        </div>
      ) : null}
      {showFicha === true ? (
        <>
          <div className='form-control'>
            <div>
              <div className='label-container'>
                <label htmlFor='DataAreaId'>DataAreaId:</label>
                <label htmlFor='DataAreaId' className='label-help'>Sigla da Entidade:</label>
              </div>
              <input
                type='text'
                name='DataAreaId'
                id='DataAreaId'
                placeholder='Digite a sigla da entidade'
                required
                value={data[0]?.DataAreaId || ''}
                onChange={e => updateFieldHandler('DataAreaId', e.target.value, 0)}
              />
            </div>
            <div>
              <div className='label-container'>
                <label htmlFor='FiscalEstablishmentId'>FiscalEstablishmentId:</label>
                <label htmlFor='FiscalEstablishmentId' className='label-help'>Estabelecimento Físcal:</label>
              </div>
              <input
                type='text'
                name='FiscalEstablishmentId'
                id='FiscalEstablishmentId'
                placeholder='Digite o estabelicimento Fiscal'
                required
                value={data[0].FiscalEstablishmentId || ''}
                onChange={e => updateFieldHandler('FiscalEstablishmentId', e.target.value, 0)}
              />
            </div>
          </div>
          <div className='form-control' style={{ display: "flex", justifyContent: 'flex-start' }}>
            <div>
              <div className='label-container'>
                <label htmlFor='MatriculaId'>MatriculaId:</label>
                <label htmlFor='MatriculaId' className='label-help'>Matrícula do Aluno:</label>
              </div>
              <input
                type='text'
                name='MatriculaId'
                id='MatriculaId'
                placeholder='Digite a matricula'
                required
                value={data[0].MatriculaId || ''}
                onChange={e => updateFieldHandler('MatriculaId', e.target.value, 0)}
              />
            </div>
          </div>
        </>
      ) : null}
      {showTransacao === true ? (
        <>
          <div className='form-control'>
            <div>
              <div className='label-container'>
                <label htmlFor='DataAreaId'>DataAreaId:</label>
                <label htmlFor='DataAreaId' className='label-help'>Sigla da Entidade:</label>
              </div>
              <input
                type='text'
                name='DataAreaId'
                id='DataAreaId'
                placeholder='Digite a sigla da entidade'
                required
                value={data[0]?.DataAreaId || ''}
                onChange={e => updateFieldHandler('DataAreaId', e.target.value, 0)}
              />
            </div>
            <div>
              <div className='label-container'>
                <label htmlFor='FiscalEstablishmentId'>FiscalEstablishmentId:</label>
                <label htmlFor='FiscalEstablishmentId' className='label-help'>Estabelecimento Físcal:</label>
              </div>
              <input
                type='text'
                name='FiscalEstablishmentId'
                id='FiscalEstablishmentId'
                placeholder='Digite o estabelicimento Fiscal'
                required
                value={data[0].FiscalEstablishmentId || ''}
                onChange={e => updateFieldHandler('FiscalEstablishmentId', e.target.value, 0)}
              />
            </div>
          </div>
          <div className='form-control' style={{ display: "flex", justifyContent: 'flex-start' }}>
            <div>
              <div className='label-container'>
                <label htmlFor='MatriculaId'>MatriculaId:</label>
                <label htmlFor='MatriculaId' className='label-help'>Matrícula do Aluno:</label>
              </div>
              <input
                type='text'
                name='MatriculaId'
                id='MatriculaId'
                placeholder='Digite a matricula'
                required
                value={data[0].MatriculaId || ''}
                onChange={e => updateFieldHandler('MatriculaId', e.target.value, 0)}
              />
            </div>
            <div>
              <div className='label-container'>
                <label htmlFor='financialMovType'>financialMovType:</label>
                <label htmlFor='financialMovType' className='label-help'>Tipo de Movimentação:</label>
              </div>
              <input
                type='number'
                name='financialMovType'
                id='financialMovType'
                placeholder='Digite o financialMovType'
                value={data[0]?.financialMovType || ''}
                onChange={e => updateFieldHandler('financialMovType', e.target.value, 0)}
              />
            </div>
          </div>
          <div className='form-control' style={{ display: "flex", justifyContent: 'flex-start' }}>
            <div>
              <div className='label-container'>
                <label htmlFor='custAccount'>custAccount:</label>
                <label htmlFor='custAccount' className='label-help'>Conta do Cliente:</label>
              </div>
              <input
                type='text'
                name='custAccount'
                id='custAccount'
                placeholder='Digite o custAccount'
                value={data[0]?.custAccount || ''}
                onChange={e => updateFieldHandler('custAccount', e.target.value, 0)
                }
              />
            </div>
            <div>
              <div className='label-container'>
                <label htmlFor='transStatus'>transStatus:</label>
                <label htmlFor='transStatus' className='label-help'>Status da Transação:</label>
              </div>
              <input
                type='number'
                name='transStatus'
                id='transStatus'
                placeholder='Digite o transStatus'
                value={data[0]?.transStatus || ''}
                onChange={e => updateFieldHandler('transStatus', e.target.value, 0)}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default UserFormOV