import React from 'react'
import UserFormOVInp from '../forms/inputsOVs'
import UserFormFichaInp from '../forms/inputsFichas'
import UserFormInpTransacao from '../forms/inputsTransacao'
import './modal.css'
const Modal = ({
  data,
  updateFieldsHandler,
  updateFieldHandler,
  handleModalOpen,
  index,
  handleFieldsUpdate
}) => {
  return (
    <div className='Modalback' onClick={() => handleModalOpen(index)}>
      <div className='InputsOVsModal'>
        {data[0].hasOwnProperty('GrossAmount') ? (
          <UserFormOVInp
            data={data}
            handleFieldsUpdate={handleFieldsUpdate}
            updateFieldsHandler={updateFieldsHandler}
            handleModalOpen={handleModalOpen}
            index={index}
            updateFieldHandler={updateFieldHandler}
          />
        ) : data[0].hasOwnProperty('RefCustSettlement') ? (
          <UserFormFichaInp
            data={data}
            handleFieldsUpdate={handleFieldsUpdate}
            updateFieldsHandler={updateFieldsHandler}
            handleModalOpen={handleModalOpen}
            index={index}
            updateFieldHandler={updateFieldHandler}
          />
        ) : (
          <UserFormInpTransacao
            data={data}
            handleFieldsUpdate={handleFieldsUpdate}
            updateFieldsHandler={updateFieldsHandler}
            handleModalOpen={handleModalOpen}
            index={index}
            updateFieldHandler={updateFieldHandler}
          />
        )}
      </div>
    </div>
  )
}

export default Modal
