import React, { useEffect, useState } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { BrowserAuthError, InteractionType } from "@azure/msal-browser";
import { fetchImageGet } from "../fetch";

const AuthContext = React.createContext({
    userImage: "",
    setUserImage: () => { },
    appContext: {},
});

const AuthProvider = ({ children }) => {
    const scopes = ["961b1dd4-360d-4014-9c4b-f6e2ecf49329/.default"];
    const { result, error, login } = useMsalAuthentication(InteractionType.Popup, { scopes: scopes });
    const [userImage, setUserImage] = useState(null);
    const msal = useMsal();
    const fetchGraphData = async () => {
        try {
            const resp = await msal.instance.acquireTokenSilent({
                scopes: ["user.read", "group.read.all"],
            });
            const object = await fetchImageGet("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", resp.accessToken);
            let blob = new Blob([object], { type: "image/jpeg" });
            let newUrl = URL.createObjectURL(blob);
            setUserImage(newUrl);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (!!error) {
            if (error instanceof BrowserAuthError) {
                login(InteractionType.Redirect, {
                    scopes: ["user.read", "group.read.all"]
                })
            }
            console.log(error);
        }
        if (result) {
            sessionStorage.setItem('@JSONAPP/msalToken', result.accessToken);
            sessionStorage.setItem('@JSONAPP/nomeUsuarioLogado', result.account.name);
            sessionStorage.setItem('@JSONAPP/emailUsuarioLogado', result.account.username);
        }

        fetchGraphData();
    }, [error, result]);
    if (sessionStorage.getItem('@JSONAPP/msalToken') === null && !result) {
        return null
    }
    if (error) {
        return null
    }
    return (
        <AuthContext.Provider value={{ userImage, setUserImage }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };