import './inputs.css'

const UserFormOVInp = ({
  data,
  updateFieldHandler,
  updateFieldsHandler,
  handleModalOpen,
  handleFieldsUpdate,
  index
}) => {
  let adjustDate = []
  let adjustDate2 = []
  data.map((item, i) => {
    if (item.DeliveryDate) {
      const deleverydate = item.DeliveryDate.slice(0, 10)
      adjustDate[i] = {
        DeliveryDate: deleverydate
      }
    }
    if (item.StartSchoolYear) {
      const StartSchoolYear = item.StartSchoolYear.slice(0, 10)
      adjustDate2[i] = {
        StartSchoolYear: StartSchoolYear
      }
    }
    return null
  })
  const handleClickInside = (e) => {
    e.stopPropagation(); // Impede a propagação do evento
  };
  return (
    <div className='form-container-input' onClick={handleClickInside}>
      <div className='closemodalSty'>

      </div>
      <div className='form-control formTitulo'>
        <div className='Titulo'>{data[index]?.ItemId} </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='CustAccount'>CustAccount</label>
            <label htmlFor='Month' className='label-help'>Conta:</label>
          </div>
          <input
            type='text'
            name='CustAccount'
            id='CustAccount'
            placeholder='Conta do responsável'
            required
            value={data[0]?.CustAccount || ''}
            onChange={e =>
              updateFieldsHandler('CustAccount', e.target.value, 0)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='Month'>Month:</label>
            <label htmlFor='Month' className='label-help'>Mês:</label>
          </div>
          <input
            type='number'
            name='Month'
            id='Month'
            placeholder='Digite o mês'
            value={data[index]?.Month || ''}
            onChange={e => handleFieldsUpdate('Month', e.target.value, index)}
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='Year'>Year:</label>
            <label htmlFor='Year' className='label-help'>Ano:</label>
          </div>
          <input
            type='number'
            name='Year'
            id='Year'
            placeholder='Digite o ano'
            value={data[index]?.Year || ''}
            onChange={e => handleFieldsUpdate('Year', e.target.value, index)}
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='AmountCur'>AmountCur:</label>
            <label htmlFor='AmountCur' className='label-help'>Bruto da Parcela:</label>
          </div>
          <input
            type='number'
            name='AmountCur'
            id='AmountCur'
            placeholder='Digite o valor atual'
            value={data[index]?.AmountCur || ''}
            onChange={e =>
              handleFieldsUpdate('AmountCur', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='DiscAmountCur'>DiscAmountCur:</label>
            <label htmlFor='DiscAmountCur' className='label-help'>Valor do Desconto:</label>
          </div>
          <input
            type='number'
            name='DiscAmountCur'
            id='DiscAmountCur'
            placeholder='Digite o valor do desconto'
            value={data[index]?.DiscAmountCur || ''}
            onChange={e =>
              handleFieldsUpdate('DiscAmountCur', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='configId'>configId:</label>
            <label htmlFor='configId' className='label-help'>Configuração:</label>
          </div>
          <select
            name='configId'
            id='configId'
            value={data[index]?.configId}
            onChange={e =>
              handleFieldsUpdate('configId', e.target.value, index)
            }
          >
            <option value='MENSALIDADE'>MENSALIDADE</option>
            <option value='1ª PARCELA'>1ª PARCELA</option>
          </select>
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='InventLocationId'>InventLocationId:</label>
            <label htmlFor='InventLocationId' className='label-help'>Código da Turma:</label>
          </div>
          <input
            type='text'
            name='InventLocationId'
            id='InventLocationId'
            placeholder='Digite o ID da localização'
            value={data[index]?.InventLocationId || ''}
            onChange={e =>
              handleFieldsUpdate('InventLocationId', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='FiscalEstablishment'>FiscalEstablishment:</label>
            <label htmlFor='FiscalEstablishment' className='label-help'>Estabelecimento Físcal:</label>
          </div>
          <input
            type='text'
            name='FiscalEstablishment'
            id='FiscalEstablishment'
            placeholder='Digite o estabelecimento fiscal'
            value={data[index]?.FiscalEstablishment || ''}
            onChange={e =>
              handleFieldsUpdate('FiscalEstablishment', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='PaymSched'>PaymSched:</label>
            <label htmlFor='PaymSched' className='label-help'>Plano de Pagamento:</label>
          </div>
          <input
            type='text'
            name='PaymSched'
            id='PaymSched'
            placeholder='Digite o PaymSched'
            value={data[index]?.PaymSched || ''}
            onChange={e =>
              handleFieldsUpdate('PaymSched', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='Scholarship'>Scholarship:</label>
            <label htmlFor='Scholarship' className='label-help'>Valor da Bolsa:</label>
          </div>
          <input
            type='number'
            name='Scholarship'
            id='Scholarship'
            placeholder='Digite a Scholarship'
            value={data[index]?.Scholarship || ''}
            onChange={e =>
              handleFieldsUpdate('Scholarship', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='GrossAmount'>GrossAmount:</label>
            <label htmlFor='GrossAmount' className='label-help'>Valor Bruto:</label>
          </div>
          <input
            type='number'
            name='GrossAmount'
            id='GrossAmount'
            placeholder='Digite o GrossAmount'
            value={data[index]?.GrossAmount || ''}
            onChange={e =>
              handleFieldsUpdate('GrossAmount', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='MethodDiscount'>MethodDiscount:</label>
            <label htmlFor='MethodDiscount' className='label-help'>Método de Desconto:</label>
          </div>
          <input
            type='number'
            name='MethodDiscount'
            id='MethodDiscount'
            placeholder='Digite o MethodDiscount'
            value={data[index]?.MethodDiscount || ''}
            onChange={e =>
              handleFieldsUpdate('MethodDiscount', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='BillingItemId'>BillingItemId:</label>
            <label htmlFor='BillingItemId' className='label-help'>ID do Item de Cobrança:</label>
          </div>
          <input
            type='text'
            name='BillingItemId'
            id='BillingItemId'
            placeholder='Digite o BillingItemId'
            value={data[index]?.BillingItemId || ''}
            onChange={e =>
              handleFieldsUpdate('BillingItemId', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='MatriculaID'>MatriculaID:</label>
            <label htmlFor='MatriculaID' className='label-help'>Matrícula do Aluno:</label>
          </div>
          <input
            type='text'
            name='MatriculaID'
            id='MatriculaID'
            placeholder='Digite o ID da matrícula'
            value={data[index]?.MatriculaID || ''}
            onChange={e =>
              handleFieldsUpdate('MatriculaID', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='TypeOfMovement'>TypeOfMovement:</label>
            <label htmlFor='TypeOfMovement' className='label-help'>Tipo de Movimentação:</label>
          </div>
          <input
            type='number'
            name='TypeOfMovement'
            id='TypeOfMovement'
            placeholder='Digite o tipo de movimento'
            value={data[index]?.TypeOfMovement || ''}
            onChange={e =>
              handleFieldsUpdate('TypeOfMovement', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='StartSchoolYear'>StartSchoolYear:</label>
            <label htmlFor='StartSchoolYear' className='label-help'>Ano Letivo:</label>
          </div>
          <input
            type='date'
            name='StartSchoolYear'
            id='StartSchoolYear'
            placeholder='Digite o ano de início escolar'
            value={adjustDate2[index]?.StartSchoolYear || ''}
            onChange={e =>
              handleFieldsUpdate('StartSchoolYear', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='CashDiscAmount'>CashDiscAmount:</label>
            <label htmlFor='CashDiscAmount' className='label-help'>Valor do Desconto em dinheiro:</label>
          </div>
          <input
            type='number'
            name='CashDiscAmount'
            id='CashDiscAmount'
            placeholder='Digite o valor do desconto à vista'
            value={data[index]?.CashDiscAmount || ''}
            onChange={e =>
              handleFieldsUpdate('CashDiscAmount', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='MethodDiscountPercentage'>MethodDiscountPercentage:</label>
            <label htmlFor='MethodDiscountPercentage' className='label-help'>Porcentagem do Desconto de Método:</label>
          </div>
          <input
            type='number'
            name='MethodDiscountPercentage'
            id='MethodDiscountPercentage'
            placeholder='Digite o percentual de desconto'
            value={data[index]?.MethodDiscountPercentage || ''}
            onChange={e =>
              handleFieldsUpdate(
                'MethodDiscountPercentage',
                e.target.value,
                index
              )
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='NetValue'>NetValue:</label>
            <label htmlFor='NetValue' className='label-help'>Valor Líquido:</label>
          </div>
          <input
            type='number'
            name='NetValue'
            id='NetValue'
            placeholder='Digite o valor líquido'
            value={data[index]?.NetValue || ''}
            onChange={e =>
              handleFieldsUpdate('NetValue', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='CashDiscAmountCur'>CashDiscAmountCur:</label>
            <label htmlFor='CashDiscAmountCur' className='label-help'>Valor do Desconto em REAIS:</label>
          </div>
          <input
            type='number'
            name='CashDiscAmountCur'
            id='CashDiscAmountCur'
            placeholder='Enter Cash Disc Amount Cur'
            value={data[index]?.CashDiscAmountCur || ''}
            onChange={e =>
              handleFieldsUpdate('CashDiscAmountCur', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='DeliveryDate'>DeliveryDate:</label>
            <label htmlFor='DeliveryDate' className='label-help'>Data de Entrega:</label>
          </div>
          <input
            type='date'
            name='DeliveryDate'
            id='DeliveryDate'
            placeholder='Enter Delivery Date'
            value={adjustDate[index]?.DeliveryDate || ''}
            onChange={e =>
              handleFieldsUpdate('DeliveryDate', e.target.value, index)
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='InstallmentNum'>InstallmentNum:</label>
            <label htmlFor='InstallmentNum' className='label-help'>...:</label>
          </div>
          <input
            type='number'
            name='InstallmentNum'
            id='InstallmentNum'
            placeholder='Enter Installment Num'
            value={data[index]?.InstallmentNum || ''}
            onChange={e =>
              handleFieldsUpdate('InstallmentNum', e.target.value, index)
            }
          />
        </div>
      </div>

      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='EnrollmentSalesOrderRecId'>EnrollmentSalesOrderRecId:</label>
            <label htmlFor='EnrollmentSalesOrderRecId' className='label-help'>ID da Ordem de Venda:</label>
          </div>
          <input
            type='number'
            name='EnrollmentSalesOrderRecId'
            id='EnrollmentSalesOrderRecId'
            placeholder='Enter Enrollment Sales Order RecId'
            value={data[index]?.EnrollmentSalesOrderRecId || ''}
            onChange={e =>
              handleFieldsUpdate(
                'EnrollmentSalesOrderRecId',
                e.target.value,
                index
              )
            }
          />
        </div>
        <div>
          <div className='label-container'>
            <label htmlFor='SegmentCode'>SegmentCode:</label>
            <label htmlFor='SegmentCode' className='label-help'>Código de Segmento:</label>
          </div>
          <input
            type='text'
            name='SegmentCode'
            id='SegmentCode'
            placeholder='Enter Segment Code'
            value={data[index]?.SegmentCode || ''}
            onChange={e =>
              handleFieldsUpdate('SegmentCode', e.target.value, index)
            }
          />
        </div>
      </div>
      <div className='form-control'>
        <div>
          <div className='label-container'>
            <label htmlFor='ScholarshipPercentage'>ScholarshipPercentage:</label>
            <label htmlFor='ScholarshipPercentage' className='label-help'>Porcentagem da Bolsa:</label>
          </div>
          <input
            type='number'
            name='ScholarshipPercentage'
            id='ScholarshipPercentage'
            placeholder='Enter Scholarship Percentage'
            value={data[index]?.ScholarshipPercentage || ''}
            onChange={e =>
              handleFieldsUpdate('ScholarshipPercentage', e.target.value, index)
            }
          />
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default UserFormOVInp
