import React, { useState } from 'react'
import './tablejson.css'
import Jsonfy from '../../utils/tratarJsonFicha'
import Swal from 'sweetalert2'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

function TableJSON ({ data, updateFieldHandler, notify, setData }) {
  let headers = Object.keys(data)
  let dataT = Jsonfy(data)
  const [selectedItemId, setSelectedItemId] = useState(data[0].ItemId)

  const filteredData = dataT._contract.FinancialStatementList.filter(
    order => order.ItemId === selectedItemId
  )
  let adjustCashdiscDueDate = []
  let adjustDueDate = []
  let adjustTransDate = []
  let adjustMovementDate = []
  data.map((item, i) => {
    if (item.CashdiscDueDate) {
      const cashediscDueDate = item.CashdiscDueDate.slice(0, 10)
      adjustCashdiscDueDate[i] = {
        CashdiscDueDate: cashediscDueDate
      }
    }
    if (item.DueDate) {
      const dueDate = item.DueDate.slice(0, 10)
      adjustDueDate[i] = {
        DueDate: dueDate
      }
    }
    if (item.TransDate) {
      const transDate = item.TransDate.slice(0, 10)
      adjustTransDate[i] = {
        TransDate: transDate
      }
    }
    if (item.MovementDate) {
      const movementDate = item.MovementDate.slice(0, 10)
      adjustMovementDate[i] = {
        MovementDate: movementDate
      }
    }
    return null
  })
  // const fixedValues = {
  //   DataAreaId: dataT._contract.DataAreaId,
  //   MatriculaId: dataT._contract.MatriculaId
  // }
  function organizarHeaders () {
    // headers = headers.filter(header => header !== 'FinancialStatementList') // filtra o header para não trazer o FinancialStatementList
    headers = headers.filter(header => isNaN(header)) // filtra o header para não trazer numeros
    //https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/isNaN
    const salesOrderHeaders = Object.keys(
      dataT._contract.FinancialStatementList[0]
    ) // pega o FinancialStatementList e retorna os headers
    return (headers = [...headers, ...salesOrderHeaders]) // retorna os headers e o FinancialStatementList em um array só
  }

  const itemIds = data.map(item => item.ItemId)
  const uniqueItemIds = []

  for (let i = 0; i < itemIds.length; i++) {
    const itemId = itemIds[i]

    if (!uniqueItemIds.includes(itemId)) {
      uniqueItemIds.push(itemId)
    }
  }

  const handleChange = event => {
    setSelectedItemId(event.target.value)
  }
  const handleDelete = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Tem certeza que deseja deletar esta linha?',
      text: 'Você não poderá reverter esta ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Não, cancelar!',
    }).then(result => {
      if (result.isConfirmed) {
        let newData = [...data]
        newData.splice(event.target.value, 1)
        setData(newData)
        notify('Item removido com sucesso!', 'success')
      }
    })
  }
  return (
    <div>
      <div className='headerTable'>
        <h1>Tabela de Ficha Financeira</h1>
        <div>
          <label htmlFor='itemIdTitle'>Filtrar por ItemId:</label>
          <select
            name='itemIdTitle'
            className='InputTable'
            value={selectedItemId}
            onChange={handleChange}
          >
            {uniqueItemIds.map(itemId => (
              <option key={itemId} value={itemId}>
                {itemId}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='tableContainer'>
        <table>
          <thead>
            <tr>
              <th className='sticky-box'>Remover</th>
              {organizarHeaders().map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((order, indexSales) => (
              <tr key={indexSales}>
                <td key={indexSales} className='sticky-box'>
                <Button value={indexSales} onClick={handleDelete} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              </td>
                {Object.keys(order).map((key, indexKey) => {
                  const value = order[key]
                  const handleChange = e => {
                    const updatedData = [...data]
                    const dataIndex = updatedData.findIndex((item) => item.ItemId === selectedItemId);
                    let index = dataIndex + indexSales
                    updateFieldHandler(key, e.target.value, index)
                  }
                  if (key === 'ConfigId') {
                    return (
                      <td key={indexKey}>
                        <select
                          className='InputTable'
                          value={value}
                          onChange={handleChange}
                        >
                          {value === 'MENSALIDADE' ? (
                            <>
                              <option value='MENSALIDADE'>MENSALIDADE</option>
                              <option value='1ª PARCELA'>1ª PARCELA</option>
                            </>
                          ) : (
                            <>
                              <option value='1ª PARCELA'>1ª PARCELA</option>
                              <option value='MENSALIDADE'>MENSALIDADE</option>
                            </>
                          )}
                        </select>
                      </td>
                    )
                  } else if (key === 'CashdiscDueDate') {
                    return (
                      <td key={indexKey}>
                        <input
                          type='date'
                          name='CashdiscDueDate'
                          id='CashdiscDueDate'
                          className='InputTable'
                          value={
                            adjustCashdiscDueDate[indexSales]?.CashdiscDueDate ||
                            ''
                          }
                          onChange={handleChange}
                        />
                      </td>
                    )
                  } else if (key === 'DueDate') {
                    return (
                      <td key={indexKey}>
                        <input
                          type='date'
                          name='DueDate'
                          id='DueDate'
                          className='InputTable'
                          value={
                            adjustDueDate[indexSales]?.DueDate || ''
                          }
                          onChange={handleChange}
                        />
                      </td>
                    )
                  } else if (key === 'TransDate') {
                    return (
                      <td key={indexKey}>
                        <input
                          type='date'
                          name='TransDate'
                          id='TransDate'
                          className='InputTable'
                          value={
                            adjustTransDate[indexSales]?.TransDate || ''
                          }
                          onChange={handleChange}
                        />
                      </td>
                    )
                  } else if (key === 'MovementDate') {
                    return (
                      <td key={indexKey}>
                        <input
                          type='date'
                          name='MovementDate'
                          id='MovementDate'
                          className='InputTable'
                          value={
                            adjustMovementDate[indexSales]?.MovementDate || ''
                          }
                          onChange={handleChange}
                        />
                      </td>
                    )
                  }else {
                    return (
                      <td key={indexKey}>
                        <input
                          className='InputTable'
                          type='text'
                          value={value || ''}
                          onChange={handleChange}
                        />
                      </td>
                    )
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableJSON
