import React, { useState, useEffect } from 'react'
import './tablejson.css'
import Swal from 'sweetalert2'
import Jsonfy from '../../utils/tratarJsonTransacao'
import filtrarJson from '../../utils/FiltrarJsonTransacao'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

function TableJSONTransacao ({ data, updateFieldHandler, setData, notify }) {
  let headers = Object.keys(data)
  let dataT = Jsonfy(data)
  let dataF = filtrarJson(data)
  let adjustDate = []
  let adjustDate2 = []
  let adjustDate3 = []
  data.map((item, i) => {
    if (item.dueDate) {
      const DueDate = item.dueDate.slice(0, 10)
      adjustDate[i] = {
        dueDate: DueDate
      }
    }
    if (item.invoiceDate) {
      const invoiceDate = item.invoiceDate.slice(0, 10)
      adjustDate2[i] = {
        invoiceDate: invoiceDate
      }
    }
    if (item.cashDiscDate) {
      const cashDiscDate = item.cashDiscDate.slice(0, 10)
      adjustDate3[i] = {
        cashDiscDate: cashDiscDate
      }
    }
    return null
  })

  const [selectedItemId, setSelectedItemId] = useState(data[0].itemId)
  let path
  const verPath = () => {
    let path = []
    if (
      dataT._contract.financialTransDetail.transactions.service
        .monthlyPayment != null
    ) {
      path =
        dataT._contract.financialTransDetail.transactions.service.monthlyPayment
    } else if (
      dataT._contract.financialTransDetail.transactions.service
        .firstInstallment != null
    ) {
      path =
        dataT._contract.financialTransDetail.transactions.service
          .firstInstallment
    } else if (
      dataT._contract.financialTransDetail.transactions.material
        .monthlyPayment != null
    ) {
      path =
        dataT._contract.financialTransDetail.transactions.material
          .monthlyPayment
    } else if (
      dataT._contract.financialTransDetail.transactions.material
        .firstInstallment != null
    ) {
      path =
        dataT._contract.financialTransDetail.transactions.material
          .firstInstallment
    } else if (
      dataT._contract.financialTransDetail.transactions.upSelling != null
    ) {
      path = dataT._contract.financialTransDetail.transactions.upSelling
    } else if (
      dataT._contract.financialTransDetail.transactions.feesEvents != null
    ) {
      path = dataT._contract.financialTransDetail.transactions.feesEvents
    }

    return path
  }
  path = verPath()
  const filteredData = dataF.filter(
    order => order.itemId === selectedItemId
  )

  function organizarHeaders () {
    headers = headers.filter(header => isNaN(header)) // filtra o header para não trazer numeros
    headers = headers.filter(header => header !== 'itemId')
    const transactions = Object.keys(dataF[0]) // pega o FinancialStatementList e retorna os headers
    return (headers = [...headers, ...transactions]) // retorna os headers e o FinancialStatementList em um array só
  }

  const itemIds = data.map(item => item.itemId)
  const uniqueItemIds = []

  for (let i = 0; i < itemIds.length; i++) {
    const itemId = itemIds[i]
    if (!uniqueItemIds.includes(itemId)) {
      uniqueItemIds.push(itemId)
    }
  }

  const handleChange = event => {
    setSelectedItemId(event.target.value)
  }

  const handleDelete = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Tem certeza que deseja deletar esta linha?',
      text: 'Você não poderá reverter esta ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Não, cancelar!',
    }).then(result => {
      if (result.isConfirmed) {
        let newData = [...data]
        newData.splice(event.target.value, 1)
        setData(newData)
        notify('Item removido com sucesso!', 'success')
      } 
    })
  }
  return (
    <div key='table'>
      <div className='headerTable'>
        <h1>Tabela de Transacao</h1>
        <div>
          <label htmlFor='itemIdTitle'>Filtrar por ItemId:</label>
          <select
            name='itemIdTitle'
            className='InputTable'
            value={selectedItemId}
            onChange={handleChange}
          >
            {uniqueItemIds.map(itemId => (
              <option key={itemId} value={itemId}>
                {itemId}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='tableContainer'>
        <table>
          <thead>
            <tr>
              <th className='sticky-box'>Remover</th>
              {organizarHeaders().map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((order, indexSales) => (
              <tr key={indexSales}>
                <td key={indexSales} className='sticky-box'>
                <Button value={indexSales} onClick={handleDelete} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              </td>
                {Object.keys(order).map((key, indexKey) => {
                  const value = order[key]
                  const handleChange = e => {
                    const updatedData = [...data]
                    const dataIndex = updatedData.findIndex((item) => item.itemId === selectedItemId);
                    let index = dataIndex + indexSales
                    updateFieldHandler(key, e.target.value, index)
                  }
                  if (key === 'configId') {
                    return (
                      <td key={indexKey}>
                        <select
                          className='InputTable'
                          value={value}
                          onChange={handleChange}
                        >
                          {value === 'MENSALIDADE' ? (
                            <>
                              <option value='MENSALIDADE'>MENSALIDADE</option>
                              <option value='1ª PARCELA'>1ª PARCELA</option>
                            </>
                          ) : (
                            <>
                              <option value='1ª PARCELA'>1ª PARCELA</option>
                              <option value='MENSALIDADE'>MENSALIDADE</option>
                            </>
                          )}
                        </select>
                      </td>
                    )
                  } else if (key === 'invoiceDate') {
                    return (
                      <td key={indexKey}>
                        <input
                          type='date'
                          className='InputTable'
                          name='invoiceDate'
                          id='invoiceDate'
                          value={
                            adjustDate2[indexSales]?.invoiceDate || ''
                          }
                          onChange={handleChange}
                        />
                      </td>
                    )
                  } else if (key === 'dueDate') {
                    return (
                      <td key={indexKey}>
                        <input
                          type='date'
                          className='InputTable'
                          name='adjustDate'
                          id='adjustDate'
                          value={
                            adjustDate[indexSales]?.dueDate || ''
                          }
                          onChange={handleChange}
                        />
                      </td>
                    )
                  } else if (key === 'cashDiscDate') {
                    return (
                      <td key={indexKey}>
                        <input
                          type='date'
                          className='InputTable'
                          name='cashDiscDate'
                          id='cashDiscDate'
                          value={
                            adjustDate3[indexSales]?.cashDiscDate || ''
                          }
                          onChange={handleChange}
                        />
                      </td>
                    )
                  } else {
                    return (
                      <td key={indexKey}>
                        <input
                          className='InputTable'
                          type='text'
                          value={value || ''}
                          onChange={handleChange}
                        />
                      </td>
                    )
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableJSONTransacao
