import React, { useState } from 'react'
import './tablejson.css'
import Jsonfy from '../../utils/tratarJson'
import Swal from 'sweetalert2'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

function TableJSON({ data, updateFieldHandler, notify, setData }) {
  let headers = Object.keys(data)
  let dataT = Jsonfy(data)
  const [selectedItemId, setSelectedItemId] = useState(data[0].ItemId)
  let adjustDate = []
  let adjustDate2 = []
  data.map((item, i) => {
    if (item.DeliveryDate) {
      const deleverydate = item.DeliveryDate.slice(0, 10)
      adjustDate[i] = {
        DeliveryDate: deleverydate
      }
    }
    if (item.StartSchoolYear) {
      const startSchoolYear = item.StartSchoolYear.slice(0, 10)
      adjustDate2[i] = {
        StartSchoolYear: startSchoolYear
      }
    }
    return null
  })

  const filteredData = dataT._contract.SalesOrderList.filter(
    order => order.ItemId === selectedItemId
  )

  function organizarHeaders() {
    headers = headers.filter(header => isNaN(header)) // filtra o header para não trazer numeros
    //https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/isNaN
    const salesOrderHeaders = Object.keys(dataT._contract.SalesOrderList[0]) // pega o salesorderlist e retorna os headers
    return (headers = [...headers, ...salesOrderHeaders]) // retorna os headers e o salesorderlist em um array só
  }

  const itemIds = data.map(item => item.ItemId)
  const uniqueItemIds = []

  for (let i = 0; i < itemIds.length; i++) {
    const itemId = itemIds[i]

    if (!uniqueItemIds.includes(itemId)) {
      uniqueItemIds.push(itemId)
    }
  }

  const handleChange = event => {
    setSelectedItemId(event.target.value)
  }

  const handleDelete = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Tem certeza que deseja deletar esta linha?',
      text: 'Você não poderá reverter esta ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Não, cancelar!',
    }).then(result => {
      if (result.isConfirmed) {
        let newData = [...data]
        newData.splice(event.target.value, 1)
        setData(newData)
        notify('Item removido com sucesso!', 'success')
      }
    })
  }
  //pegar o data do event[index]
return (
  <div>
    <div className='headerTable'>
      <h1>Tabela de OV</h1>
      <div>
        <label htmlFor='itemIdTitle'>Filtrar por ItemId:</label>
        <select
          name='itemIdTitle'
          className='InputTable'
          value={selectedItemId}
          onChange={handleChange}
        >
          {uniqueItemIds.map(itemId => (
            <option key={itemId} value={itemId}>{itemId}</option>
          ))}
        </select>
      </div>
    </div>
    <div className='tableContainer'>
      <table>
        <thead>
          <tr>
            <th key='checkbox' className='sticky-box' >
              Remover
            </th>
            {organizarHeaders().map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* mapeia o salesorderlist  dentro do _contract */}
          {/* cria uma linha para cada objeto do array */}
          {filteredData.map((order, indexSales) => (
            <tr key={indexSales}>
              <td key={indexSales} className='sticky-box'>
                <Button value={indexSales} onClick={handleDelete} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              </td>
              {Object.keys(order).map((key, indexKey) => {
                const value = order[key]
                const handleChange = e => {
                  const updatedData = [...data]
                  const dataIndex = updatedData.findIndex((item) => item.ItemId === selectedItemId);
                  let index = dataIndex + indexSales
                  updateFieldHandler(key, e.target.value, index)
                }
                if (key === 'configId') {
                  return (
                    <td key={indexKey}>
                      <select
                        className='InputTable'
                        value={value}
                        onChange={handleChange}
                      >
                        {value === 'MENSALIDADE' ? (
                          <>
                            <option value='MENSALIDADE'>MENSALIDADE</option>
                            <option value='1ª PARCELA'>1ª PARCELA</option>
                          </>
                        ) : (
                          <>
                            <option value='1ª PARCELA'>1ª PARCELA</option>
                            <option value='MENSALIDADE'>MENSALIDADE</option>
                          </>
                        )
                        }
                      </select>
                    </td>
                  )
                } else if (key === 'DeliveryDate') {
                  return (
                    <td key={indexKey}>
                      <input
                        type='date'
                        name='DeliveryDate'
                        className='InputTable'
                        id='DeliveryDate'
                        value={
                          adjustDate[indexSales]?.DeliveryDate || ''
                        }
                        onChange={handleChange}
                      />
                    </td>
                  )
                } else if (key === 'StartSchoolYear') {
                  return (
                    <td key={indexKey}>
                      <input
                        type='date'
                        name='StartSchoolYear'
                        className='InputTable'
                        id='StartSchoolYear'
                        value={
                          adjustDate2[indexSales]?.StartSchoolYear || ''
                        }
                        onChange={handleChange}
                      />
                    </td>
                  )
                } else {
                  return (
                    <td key={indexKey}>
                      <input
                        className='InputTable'
                        type='text'
                        value={value || ''}
                        onChange={handleChange}
                      />
                    </td>
                  )
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)
}

export default TableJSON
