import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import { EventType, LogLevel, PublicClientApplication } from "@azure/msal-browser"
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createRoot} from "react-dom/client";

const pca = new PublicClientApplication({
    auth: {
        clientId: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
        authority: `${process.env.REACT_APP_OAUTH_AUTHORITY}`,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: "/",
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPII) => {
                if (containsPII) {
                    return;
                }
                switch (level) {
                    case 0: //msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case 1: //msal.LogLevel.Warning:
                        console.info(message);
                        return;
                }
            },
            logLevel: LogLevel.Verbose,
        }
    }
});

pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        // @ts-ignore
        pca.setActiveAccount(event?.payload?.account);
    }
});


const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App
            msalInstance={pca}
        />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
