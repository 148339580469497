import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import Swal from 'sweetalert2'
import './card.css'
import Modal from '../modal/modal'

const CardServico = ({
  data,
  groupedData,
  handleFieldsUpdate,
  deleteServ,
  updateFieldsHandler,
  updateFieldHandler,
  quantidadeData,
  quantidadeServ,
  notify,
  updateQuantServ
}) => {
  const handleDelete = index => {
    Swal.fire({
      title: 'Tem certeza que deseja deletar este serviço?',
      text: 'Você não poderá reverter esta ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Não, cancelar!',
    }).then(result => {
      if (result.isConfirmed) {
        deleteServ(index)
        Swal.fire({
          title: 'Deletado!',
          text: 'O serviço foi deletado.',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelado!',
          text: 'O serviço não foi deletado.',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    })
  }
  const [openModals, setOpenModals] = useState([])
  let quantidades = parseInt(quantidadeData)
  useEffect(() => {
    if (quantidades === 0 || quantidades === '' || quantidades === null) {
      return null
    }
    if (!data || !quantidadeData) {
      return null
    }

    setOpenModals(prevModals => [
      ...prevModals,
      ...Array.from({ length: quantidades - prevModals.length }, () => false)
    ])
  }, [quantidades])

  const handleModalOpen = index => {
    setOpenModals(prev => {
      const newState = [...prev]
      newState[index] = !prev[index]
      return newState
    })
  }


  //header retornando erro de key - remover.
  let header = (
    <div className='headerServicos' id='card' key='header'>
      <div className='form-control card-control'>
        <div>
          <label htmlFor='DataAreaId'>Entidade:</label>
          <input
            type='text'
            name='DataAreaId'
            id='DataAreaId'
            placeholder='Digite o nome da entidade'
            required
            value={data[0]?.DataAreaId || ''}
            onChange={e => updateFieldsHandler('DataAreaId', e.target.value, 0)}
          />
        </div>

        <div>
          <label htmlFor='MatriculaId'>Matricula</label>
          <input
            type='text'
            name='MatriculaId'
            id='MatriculaId'
            placeholder='Digite a matricula'
            required
            value={data[0]?.MatriculaId || ''}
            onChange={e =>
              updateFieldsHandler('MatriculaId', e.target.value, 0)
            }
          />
        </div>
      </div>
    </div>
  )

  let divsCont = []

  divsCont.push(header)

  let cardFunc = i => {
    if (i < 0 || i >= data.length) {
      return null
    }
    return (
      <div className='container' id='card' key={i}>
        <div className='card'>
          <div className='title'>
            <h1>{data[i].ItemId ? data[i].ItemId : 'Serviço'}</h1>
          </div>
          <div className='content'>
            <div>
              <label key={i} htmlFor={`Servico${i}`}>
                Serviço:
              </label>
              <input
                type='text'
                name={`Servico${i}`}
                id={`Servico${i}`}
                required
                placeholder='Digite o número do item'
                value={data[i].ItemId || ''}
                onChange={e => updateFieldsHandler(`ItemId`, e.target.value, i)}
                key={`Servico${i}`}
              />
            </div>
            <div>
              <label key={i} htmlFor='numParcelas'>
                Quant. de parcelas
              </label>
              <select
                key={`numParcelas${i}`}
                name={`numParcelas${i}`}
                id={`numParcelas${i}`}
                onChange={e =>
                  updateFieldsHandler('numParcelas', e.target.value, i)
                }
                value={data[i].numParcelas || ''}
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
                <option value='13'>13</option>
                <option value='14'>14</option>
                <option value='15'>15</option>
                <option value='16'>16</option>
                <option value='17'>17</option>
              </select>
            </div>
            <div className='btn-container'>
              {!data[i].ItemId ? (
                <div disabled className='custom-btn ' style={{ opacity: 0.4 }}>
                  <span>Visualizar</span>
                </div>
              ) : (
                <div className='custom-btn ' onClick={() => handleModalOpen(i)}>
                  <span>Visualizar</span>
                </div>
              )}
              <div className='custom-btn delete ' onClick={() => handleDelete(i)}>
                <span>Deletar</span>
              </div>
            </div>
          </div>
          <div className='circle'></div>
        </div>
        {openModals[i] && (
          <Modal
            data={data}
            handleFieldsUpdate={handleFieldsUpdate}
            updateFieldHandler={updateFieldHandler}
            updateFieldsHandler={updateFieldsHandler}
            handleModalOpen={handleModalOpen}
            index={i}
          />
        )}
      </div>
    )
  }

  let quantidadeParc = 0
  for (const key in groupedData) {
    quantidadeParc += groupedData[key].length
    for (let i = 0; i < groupedData[key].length; i++) {
      groupedData[key][i].numParcelas = groupedData[key].length
    }
    divsCont.push(cardFunc(quantidadeParc - groupedData[key].length))
  }

  let footer = (
    <div className='footer-container' key='footer'>
      <div
        className='custom-btn adicionarserv '
        onClick={() => updateQuantServ(quantidadeServ + 1)}
      >
        <AddIcon color='primary' />
        <span>Adicionar Servico</span>
      </div>
    </div>
  )
  divsCont.push(footer)
  return divsCont
}

export default CardServico
