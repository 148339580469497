const Jsonfy = (dataReceived) => {
    const FinancialStatementList = dataReceived.map((item) => {
      return {
        "InstallmentAmountCur": parseFloat(item.InstallmentAmountCur) || 0,
        "InstallmentNetAmount": parseFloat(item.InstallmentNetAmount) || 0,
        "PaymentMethod": item.PaymentMethod || '',       
        "TotalDiscount": parseFloat(item.TotalDiscount) || 0,
        "CashdiscDueDate": item.CashdiscDueDate || '0001-01-01T12:00:00',
        "CashDiscValue": parseFloat(item.CashDiscValue) || 0,
        "DueDate": item.DueDate || '0001-01-01T12:00:00',
        "InvoiceId": item.InvoiceId || '',
        "ItemId": item.ItemId || '',
        "Installment": parseFloat(item.Installment) || 0,
        "ConfigId": item.ConfigId || '',
        "Responsible": item.Responsible || '',
        "TransDate": item.TransDate || '0001-01-01T12:00:00',
        "ReserveValue": parseFloat(item.ReserveValue) || 0,
        "ScholarshipValue": parseFloat(item.ScholarshipValue) || 0,
        "PaymMethodDiscAmount": parseFloat(item.PaymMethodDiscAmount) || 0,
        "TransactionType": parseFloat(item.TransactionType) || 0,
        "TypeOfMovement": parseFloat(item.TypeOfMovement) || 0,
        "MovementDate": item.MovementDate || '0001-01-01T12:00:00',
        "MatriculaId": item.MatriculaId || '',
        "PaymentStatus": parseFloat(item.PaymentStatus) || 0,
        "AmountPaid": parseFloat(item.AmountPaid) || 0,
        "TypeOfPayment": parseFloat(item.TypeOfPayment) || 0,
        "AgreementDocument": item.AgreementDocument || null,
        "RefCustTrans": parseFloat(item.RefCustTrans) || 0,
        "RefCustTransOpen": parseFloat(item.RefCustTransOpen) || 0,
        "RefCustSettlement": parseFloat(item.RefCustSettlement) || 0
      }
    });
    return {
      "_contract": {
        "DataAreaId": dataReceived[0].DataAreaId, 
        "FiscalEstablishmentId": dataReceived[0].FiscalEstablishmentId,
        "MatriculaId": dataReceived[0].MatriculaId,
        "FinancialStatementList": FinancialStatementList
      }
    };
  };
  
  export default Jsonfy;
  